import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { useAuth } from '@store/auth';
import { ROUTES } from '@common/routes';
import { useProfiler } from '@store/profiler';
import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { PreSignServiceRequest } from '@services/api/auth';
import Box from '@mui/material/Box';
import { PreSignRequest, SignInRequest } from '@proto/iam/auth/v1/auth_pb';
import { OAuthButton } from './OAuthButton/OAuthButton';
import { OAuthAppTypes } from './OAuthButton/oAuthConfig';

const StyledLink = styled(Link)(() => ({
  color: 'white',
  display: 'inline-flex',
}));

export const AuthForm = () => {
  const navigate = useNavigate();
  const { isLoading, showOtpInput, preSignIn, signIn, device } = useAuth();
  const { userProfile } = useProfiler();
  const [userEmail, setUserEmail] = useState<string>('');
  useEffect(() => {
    if (userProfile) {
      navigate('/main');
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack maxWidth={{ xs: 340, md: 280, xl: 320 }}>
      {!showOtpInput && (
        <Box>
          <OAuthButton type={OAuthAppTypes.google} />
          <Typography variant="body1" mt={3.5} textAlign="center">
            or
          </Typography>
          <Form<PreSignServiceRequest>
            formProps={{ defaultValues: { email: '' } }}
            submitHandler={(data) => {
              setUserEmail(data?.email || '');
              // @ts-ignore
              preSignIn({ email: data.email, device });
            }}
          >
            <InputController
              name="email"
              label="Email"
              variant="standard"
              type="email"
              autoComplete="email"
              fullWidth
              sx={{ mb: 3 }}
            />
            <LoadingButton type="submit" variant="outlined" loading={isLoading} fullWidth>
              Continue with Email
            </LoadingButton>
          </Form>

          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }} my={3}>
            By continuing I agree to{' '}
            <StyledLink to={ROUTES.LEGAL_PRIVACY}>Privacy Policy</StyledLink>
            <br />
            and <StyledLink to={ROUTES.LEGAL_TERMS}> Terms of Service</StyledLink>
          </Typography>
        </Box>
      )}
      {showOtpInput && (
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center" variant="subtitle1">
            Good, an OTP was sent to your email, copy and send it us
          </Typography>
          <Form
            style={{ width: '100%' }}
            formProps={{ defaultValues: { otp: '' } }}
            submitHandler={(data: any) => {
              signIn(
                new SignInRequest({
                  type: {
                    case: 'token',
                    value: data.otp,
                  },
                  device: device || undefined,
                })
              );
            }}
          >
            <InputController name="otp" label="Email code" fullWidth sx={{ mb: 3 }} />
            <LoadingButton type="submit" variant="outlined" loading={isLoading} fullWidth>
              Enter
            </LoadingButton>
          </Form>
          <Form<PreSignServiceRequest>
            formProps={{ defaultValues: { email: '' } }}
            submitHandler={(data) => {
              preSignIn(
                userEmail
                  ? new PreSignRequest({
                      email: userEmail,
                      device: device || undefined,
                    })
                  : data
              );
            }}
          >
            <LoadingButton type="submit" variant="outlined" loading={isLoading} fullWidth>
              Resend OTP
            </LoadingButton>
          </Form>
        </Box>
      )}
    </Stack>
  );
};
