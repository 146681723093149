import * as React from 'react';
import { useTags } from 'store/tags';
import {
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import TagAutocomplete from '@components/TagAutocomplete/TagAutocomplete';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';

export const techSpec = [
  'Security engineer',
  'System Architect',
  'Site Reliability Engineer',
  'Software Engineer',
  'Quality Assurance Engineer',
  'Machine Learning Engineer',
  'Data Scientist',
];

type ProfileTagsProps = {
  isOnboarding?: () => void;
};

const initialStructure: { [key: string]: TTag[] } = {
  '2': [],
  '1': [],
  '3': [],
  '4': [],
};
const createInitialStructure = () => ({
  '2': [],
  '1': [],
  '3': [],
  '4': [],
});

export function organizeTagsByType(tags: TTag[]): { [key: string]: TTag[] } {
  return tags.reduce((acc, tag) => {
    if (!acc[tag.tagType]) {
      acc[tag.tagType] = [];
    }
    acc[tag.tagType].push(tag);
    return acc;
  }, createInitialStructure() as typeof initialStructure);
}

export const ProfileTags = (props: ProfileTagsProps) => {
  const { isOnboarding } = props;
  const { data: userProfile } = useGetMyProfile();

  const {
    getRoles,
    getLanguages,
    getProgrammingLanguages,
    getIndustryDomains,
    tags,
    addTags,
    removeTags,
    setTagstatus,
  } = useTags();

  const filteredTags = React.useMemo(() => {
    return userProfile?.profile?.tags?.tags?.filter((tag) => tag.tagType !== TagType.COUNTRY) || [];
  }, [userProfile]);

  const defaultTags = React.useMemo(() => {
    return organizeTagsByType(filteredTags);
  }, [filteredTags]);

  const getTags = {
    '2': getRoles,
    '1': getLanguages,
    '3': getIndustryDomains,
    '4': getProgrammingLanguages,
  };

  const tagsLabels = {
    '2': 'Roles',
    '1': 'Languages',
    '3': 'Industry domains',
    '4': 'Programming languages',
  };

  const tagTypetoEnumValue = {
    '2': TagType.ROLE,
    '1': TagType.LANGUAGE,
    '3': TagType.INDUSTRY_DOMAIN,
    '4': TagType.PROGRAMMING_LANGUAGE,
  };
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {defaultTags &&
        Object.entries(defaultTags).map(([key, value]) => {
          return (
            <TagAutocomplete
              items={tags[key]}
              getItems={getTags[key as keyof typeof getTags]}
              defaultItems={value}
              label={tagsLabels[key as keyof typeof tagsLabels]}
              statusChanger={(id, status) => {
                statusChangeHandler(
                  id,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: Entity_Type.USER,
                    entityId: userProfile?.profile?.profileId,
                    status,
                  },
                  setTagstatus
                );
              }}
              onSelect={(tag: TTag) =>
                selectHandler(
                  tag,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: Entity_Type.USER,
                    entityId: userProfile?.profile?.profileId,
                  },
                  addTags
                )
              }
              onRemove={(tag: TTag) =>
                removeHandler(
                  tag,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: Entity_Type.USER,
                    entityId: userProfile?.profile?.profileId,
                  },
                  removeTags
                )
              }
            />
          );
        })}
      {isOnboarding && (
        <Box display="flex" justifyContent="center" gap={1}>
          <Button variant="contained" onClick={isOnboarding}>
            Next
          </Button>
          <Button onClick={isOnboarding} variant="outlined">
            Skip
          </Button>
        </Box>
      )}
    </Box>
  );
};
