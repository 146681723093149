import * as React from 'react';
import Box from '@mui/material/Box';
import AppliedDemandsSection from '@pages/Main/MainPage/AppliedDemandsSection';

const ListAppliedDemands = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <AppliedDemandsSection />
    </Box>
  );
};

export default ListAppliedDemands;
