import * as React from 'react';
import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { sortItemsByDate } from '@pages/Marketplace/Dashboards/config';
import { TFeedList } from '@services/api/marketplace/config';
import DemandList from '@pages/Marketplace/Feed/DemandList';

type TFeedListProps = {
  feedItems: TFeedList;
};

const FeedList = (props: TFeedListProps) => {
  const { feedItems } = props;
  const { displayListType } = useMarketplace();
  const contractList = Object.values(feedItems?.contracts || {});
  const interviewsList = Object.values(feedItems?.interviews || {});
  const combinedList = [...contractList, ...interviewsList];

  let displayList;
  if (displayListType === 'all') displayList = sortItemsByDate(combinedList);
  if (displayListType === 'contracts') displayList = sortItemsByDate(contractList);
  if (displayListType === 'interviews') displayList = sortItemsByDate(interviewsList);

  return (
    <Fade in timeout={500}>
      <Box
        flexDirection="column"
        display="flex"
        gap={1}
        position="relative"
        alignItems="flex-end"
        px={1}
      >
        {displayList && <DemandList displayList={displayList} />}
      </Box>
    </Fade>
  );
};

export default FeedList;
