import React from 'react';
import Box from '@mui/material/Box';
import { Badge, Popover } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Typography from '@mui/material/Typography';
import { useStreamStore } from '../../zustand/subscribe';

const NotificationPopover = () => {
  const { bellNotifications, clearBellNotifications } = useStreamStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    clearBellNotifications();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Badge badgeContent={bellNotifications?.length} color="primary" onClick={handleClick}>
        {bellNotifications?.length === 0 ? (
          <NotificationsIcon />
        ) : (
          <NotificationsActiveIcon className="BellAnimation" />
        )}
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box py={3} px={4}>
          {bellNotifications?.length === 0 ? (
            <Typography sx={{ py: 2 }}>You have no notifications.</Typography>
          ) : (
            <Box>
              {bellNotifications.map((event) => {
                return (
                  <Typography key={event.subject} sx={{ py: 2 }}>
                    {event.subject}
                  </Typography>
                );
              })}
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};
export default NotificationPopover;
