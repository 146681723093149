import Box from '@mui/material/Box';
import AddAvatarButton from '@components/Avatars/AddAvatarButton';
import React from 'react';
import AvatarLightbox from '@components/Avatars/AvatarLightbox';

const Avatars = ({ entityId, link }: { entityId: string; link: string }) => {
  return (
    <Box width="100px" display="flex" gap={2} flexDirection="column">
      <AvatarLightbox entityId={entityId} link={link} />
      <AddAvatarButton entityId={entityId} />
    </Box>
  );
};

export default Avatars;
