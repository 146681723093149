import { combineReducers } from '@reduxjs/toolkit';
import { notificatorSlice } from '@store/notificator/slices';
import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';
import { notificatorService } from '@services/api/notificator/events';
import { useCallback } from 'react';
import { TNotifications } from '@store/notificator/notificator';
import { JWT } from '@services/jwt';

const {
  actions: { pushEvent },
} = notificatorSlice;
export const notificatorReducer = combineReducers({
  notificator: notificatorSlice.reducer,
});

const subscribeNotificator = createServiceCall<any, never>(
  notificatorSlice.name,
  notificatorService.subscribe,
  async ({ response, dispatch }) => {
    console.log('mae buti notification', response);
    try {
      // eslint-disable-next-line no-restricted-syntax
      for await (const res of response) {
        console.log('mae buti notification', res);
        if (res.subject !== 'Connection') dispatch(pushEvent(res));
      }
    } catch (e) {
      console.log({ error: e });
      // dispatch(subscribeNotificator());
    }
  }
);

export const useNotificator = (): {
  notificatorEvents: TNotifications;
  notificatorSubscribe: () => void;
} => {
  const dispatch = useAppDispatch();

  return {
    notificatorEvents: useAppSelector((state) => state.notificator.notificator.events),
    notificatorSubscribe: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) dispatch(subscribeNotificator());
    }, [dispatch]),
  };
};
