import React from 'react';
import Box from '@mui/material/Box';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { Squads } from '@proto/profiler/biz/v1/squad_pb';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MembershipSquadCard from '@pages/Main/MainPage/MembershipSquadCard';
import MembershipOrgCard from '@pages/Main/MainPage/MembershipOrgCard';
import { Orgs } from '@proto/profiler/biz/v1/org_pb';

export const ListMemberships = (): JSX.Element => {
  const { data: squadsList, error: squadsError } = useListUserSquads();
  const { data: orgsList, error: orgsError } = useListUserOrgs();

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {squadsList?.squads && (
          <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
            Squads
          </Typography>
        )}
        {squadsList?.squads && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            {(squadsList?.squads as Squads)?.squads?.map((squad, index) => {
              const squadsLength = (squadsList?.squads as Squads)?.squads?.length ?? 0;
              return (
                <Box key={squad.squadId?.value} display="flex" flexDirection="column" gap={0.5}>
                  <MembershipSquadCard squad={squad} />
                  {index < squadsLength - 1 && <Divider />}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={0.5}>
        {orgsList?.orgs && (
          <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
            Organizations
          </Typography>
        )}

        {orgsList?.orgs && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            {(orgsList?.orgs as Orgs)?.orgs?.map((org, index) => {
              const orgsLength = (orgsList?.orgs as Orgs)?.orgs?.length ?? 0;
              return (
                <Box key={org.orgId?.value} display="flex" flexDirection="column" gap={0.5}>
                  <MembershipOrgCard org={org} />
                  {index < orgsLength - 1 && <Divider />}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ListMemberships;
