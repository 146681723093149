import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useListAppliedDemands } from '@services/queries/MarketplaceQueries';
import { sortItemsByDate } from '@pages/Marketplace/Dashboards/config';
import DemandAppliedCard from '@pages/Main/MainPage/DemandAppliedCard';
import ScrollableContainer from '@components/ScrollableContainerІ';

const AppliedDemandsSection = () => {
  const [currentStatus, setCurrentStatus] = useState('all');
  const { displayListType } = useMarketplace();

  const { data, isLoading } = useListAppliedDemands();
  const demands = data?.demands;

  const contractList =
    (demands && Object.values(demands[currentStatus as keyof typeof demands]?.contracts)) || [];

  const interviewsList =
    (demands && Object.values(demands[currentStatus as keyof typeof demands]?.interviews)) || [];

  const combinedList = [...contractList, ...interviewsList];

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setCurrentStatus(event.target.value as string);
  };

  let displayList;

  if (displayListType === 'all') displayList = sortItemsByDate(combinedList);
  if (displayListType === 'contracts') displayList = sortItemsByDate(contractList);
  if (displayListType === 'interviews') displayList = sortItemsByDate(interviewsList);

  return !isLoading ? (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        border="1px solid rgba(255, 255, 255, 0.12)"
        borderRadius="5px"
        display="flex"
        flexDirection="column"
        p={2}
        gap={1}
        overflow="auto"
        height="100%"
      >
        {demands ? (
          <Box display="flex" flexDirection="column" gap={1}>
            {displayList ? (
              <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {demands && (
                    <Select fullWidth value={currentStatus} onChange={handleFilterChange}>
                      {Object.keys(demands).map((status) => (
                        <MenuItem key={status} value={status}>
                          {status.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
                <ScrollableContainer maxHeight="75vh">
                  <Box display="flex" flexDirection="column" gap={1}>
                    {displayList &&
                      displayList.map((demand) => {
                        return (
                          <Box key={demand?.entity?.value?.dashboardId?.value}>
                            <DemandAppliedCard demand={demand} />
                          </Box>
                        );
                      })}
                  </Box>
                </ScrollableContainer>
              </Box>
            ) : (
              <Typography>You not have applied demands.</Typography>
            )}
          </Box>
        ) : (
          <Box display="flex" gap={1}>
            <Typography>You have not yet become a supplier.</Typography>
            <Link className="NavLink" to="/feed-list">
              <Typography sx={{ textDecoration: 'underline' }}>Explore feed</Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box>...</Box>
  );
};

export default AppliedDemandsSection;
