import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import { combineReducers } from '@reduxjs/toolkit';

import {
  ActivateAvatarServiceRequest,
  ActivateAvatarServiceResponse,
  ChangeProfileStatusServiceRequest,
  ChangeProfileStatusServiceResponse,
  DeleteAvatarServiceRequest,
  DeleteAvatarServiceResponse,
  FinishToolTipsServiceRequest,
  FinishToolTipsServiceResponse,
  GetAvatarUploadUrlServiceRequest,
  GetAvatarUploadUrlServiceResponse,
  GetMyProfileServiceRequest,
  GetMyProfileServiceResponse,
  GetProfileServiceRequest,
  GetProfileServiceResponse,
  ListAvatarsServiceRequest,
  ListAvatarsServiceResponse,
  ListProfilesServiceRequest,
  ListProfilesServiceResponse,
  ListToolTipsServiceResponse,
  PinItemServiceRequest,
  PinItemServiceResponse,
  profileService,
  SelectProfilesServiceRequest,
  SelectProfilesServiceResponse,
  TProfile,
  UnPinItemServiceRequest,
  UnPinItemServiceResponse,
  UpdateProfilePersonalInfoServiceRequest,
  UpdateProfilePersonalInfoServiceResponse,
  UpdateProfileTraitsServiceRequest,
  UpdateProfileTraitsServiceResponse,
} from '@services/api/profiler/profile';

import {
  bizService,
  InviteActionServiceRequest,
  InviteActionServiceResponse,
  ListInvitesServiceRequest,
  ListInvitesServiceResponse,
  ListUserInvitesServiceResponse,
  SendInviteServiceRequest,
  SendInviteServiceResponse,
} from '@services/api/profiler/biz';

import { useCallback } from 'react';

import {
  GetProfileRequest,
  ListProfilesRequest,
  PinItemRequest,
  PinnedItem,
  UnpinItemRequest,
  UpdateProfilePersonalInfoRequest,
} from '@proto/profiler/user/v1/profile_pb';

import { JWT } from '@services/jwt';

import {
  transformInvitesArrayToMap,
  transformSearchProfiles,
  TUserInvite,
} from '@store/profiler/helpers';
import {
  InviteAction,
  InviteActionRequest,
  ListInvitesRequest,
  ObjectInvite,
  SendInvitesRequest,
} from '@proto/profiler/invite/v1/invite_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { FinishToolTipsRequest, ToolTips } from '@proto/profiler/user/v1/tool_tips_pb';
import { Avatars } from '@proto/profiler/avatars/v1/avatar_pb';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import { TObjectInvite } from '@store/squads/squads';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { TransformedPinnedItems, transformPinItems } from '@utils/transformPinItemsToMap';
import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { showSnackbar } from '@store/snackbars';
import { Strings } from '@proto/grpc/type/v1/types_pb';
import { userSlice } from './slices';
import { Profile, TSearchProfiles } from './profiler';

const {
  actions: {
    setProfile,
    setProfileName,
    setProfileStatus,
    setSelectedProfile,
    setFoundProfiles,
    deleteSelectedProfile,
    setUserIvitesList,
    setUploadAvatarUrl,
    setUserAvatars,
    setTooltipsList,
    setCurrentProfile,
    setInvitesLists,
    setPinnedItems,
    setProfileTraits,
    setUserProfiles,
  },
} = userSlice;

export const profilerReducer = combineReducers({
  user: userSlice.reducer,
});

// PROFILE
export const getMyProfile = createServiceCall<GetMyProfileServiceResponse, never>(
  userSlice.name,
  profileService.getMyProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
    dispatch(setPinnedItems({ pinnedItems: transformPinItems(profile?.pinnedItems || []) }));
  },
  ({ dispatch }) => {
    // JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);
const getWelcomeProfile = createServiceCall<
  GetMyProfileServiceResponse,
  GetMyProfileServiceRequest
>(
  userSlice.name,
  profileService.getWelcomeProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
  },
  ({ dispatch }) => {
    // JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);
export const getEntityInvitesList = createServiceCall<
  ListInvitesServiceResponse,
  ListInvitesServiceRequest
>(userSlice.name, bizService.listInvites, ({ payload, response, dispatch }) => {
  const entityId = payload.entity?.id?.value;
  if (entityId) dispatch(setInvitesLists({ [entityId]: response.invites }));
});

export const getListProfiles = createServiceCall<
  ListProfilesServiceResponse,
  ListProfilesServiceRequest
>(userSlice.name, profileService.listProfiles, ({ response: { profiles }, dispatch }) => {
  dispatch(
    setUserProfiles(
      profiles?.profiles.reduce((acc, profile) => {
        return { ...acc, [profile?.profileId?.value as string]: profile };
      }, [])
    )
  );
});

const getProfile = createServiceCall<GetProfileServiceResponse, GetProfileServiceRequest>(
  userSlice.name,
  profileService.getProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setSelectedProfile({ profile }));
  }
);

const getCurrentProfile = createServiceCall<GetProfileServiceResponse, GetProfileServiceRequest>(
  userSlice.name,
  profileService.getProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setCurrentProfile({ profile }));
    if (profile) dispatch(setUserProfiles({ [profile?.profileId?.value as string]: profile }));
  }
);

const updateProfileName = createServiceCall<
  UpdateProfilePersonalInfoServiceResponse,
  UpdateProfilePersonalInfoServiceRequest
>(
  userSlice.name,
  profileService.updateProfileName,
  ({ response: { names }, dispatch }) => {
    dispatch(setProfileName({ names }));
    dispatch(
      showSnackbar({ message: 'Profile name updated', severity: 'success', id: 'profile-name' })
    );
  },
  ({ dispatch }) => {
    dispatch(
      showSnackbar({ message: 'Profile name update failed', severity: 'error', id: 'profile-name' })
    );
  }
);

const changeProfileStatus = createServiceCall<
  ChangeProfileStatusServiceResponse,
  ChangeProfileStatusServiceRequest
>(userSlice.name, profileService.changeProfileStatus, ({ response: { active }, dispatch }) => {
  dispatch(setProfileStatus({ active }));
});
const getAvatarUploadUrl = createServiceCall<
  GetAvatarUploadUrlServiceResponse,
  GetAvatarUploadUrlServiceRequest
>(userSlice.name, profileService.getAvatarUpload, ({ response: { url }, dispatch, state }) => {
  dispatch(setUploadAvatarUrl({ url }));
});
const deleteAvatar = createServiceCall<DeleteAvatarServiceResponse, DeleteAvatarServiceRequest>(
  userSlice.name,
  profileService.deleteAvatar
);
const getProfileAvatar = createServiceCall<ListAvatarsServiceResponse, ListAvatarsServiceRequest>(
  userSlice.name,
  profileService.listAvatars,
  ({ response: { avatars }, dispatch }) => {
    dispatch(setUserAvatars({ avatars }));
  }
);

export const activateAvatar = createServiceCall<
  ActivateAvatarServiceResponse,
  ActivateAvatarServiceRequest
>(userSlice.name, profileService.activateAvatar, ({ dispatch }) => {
  dispatch(getMyProfile());
});

const updateTraits = createServiceCall<
  UpdateProfileTraitsServiceResponse,
  UpdateProfileTraitsServiceRequest
>(
  userSlice.name,
  profileService.updateTraits,
  ({ response: { traits }, dispatch }) => {
    dispatch(setProfileTraits({ traits }));
    dispatch(
      showSnackbar({ message: 'Profile traits updated', severity: 'success', id: 'profile-traits' })
    );
  },
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        message: 'Profile traits update failed',
        severity: 'error',
        id: 'profile-traits',
      })
    );
  }
);

const getListToolTips = createServiceCall<ListToolTipsServiceResponse, never>(
  userSlice.name,
  profileService.listToolTips,
  ({ response: { toolTips }, dispatch }) => {
    if (toolTips === undefined) return;
    dispatch(setTooltipsList({ tooltips: toolTips }));
  }
);
const finishToolTips = createServiceCall<
  FinishToolTipsServiceResponse,
  FinishToolTipsServiceRequest
>(userSlice.name, profileService.finishTooltips, ({ dispatch }) => {
  dispatch(getListToolTips());
});

const getlistUserInvites = createServiceCall<ListUserInvitesServiceResponse, never>(
  userSlice.name,
  bizService.listUserInvites,
  ({ response: { invites }, dispatch }) => {
    dispatch(setUserIvitesList({ invites: transformInvitesArrayToMap(invites) }));
  },
  ({ dispatch }) => {
    dispatch(setUserIvitesList({ invites: [] }));
  }
);

const sendInvites = createServiceCall<SendInviteServiceResponse, SendInviteServiceRequest>(
  userSlice.name,
  bizService.sendInvites,
  ({ payload, dispatch }) => {
    // clear searchProfiles
    if (payload.entity)
      dispatch(
        getEntityInvitesList(
          new ListInvitesRequest({
            entity: payload.entity,
          })
        )
      );
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(showSnackbar({ message: 'Invite sent', severity: 'success', id: 'send-invites' }));
  }
);
const pinEntity = createServiceCall<PinItemServiceResponse, PinItemServiceRequest>(
  userSlice.name,
  profileService.pinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);
const unpinEntity = createServiceCall<UnPinItemServiceResponse, UnPinItemServiceRequest>(
  userSlice.name,
  profileService.unPinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);

const acceptUserInvite = createServiceCall<
  InviteActionServiceResponse,
  InviteActionServiceRequest & {
    entity?: Entity;
  }
>(userSlice.name, bizService.inviteAction, ({ payload, dispatch }) => {
  if (payload && payload?.entity) {
    // @ts-ignore
    window.location.reload();
  }

  dispatch(getlistUserInvites());
});

const closeUserInvite = createServiceCall<InviteActionServiceResponse, InviteActionServiceRequest>(
  userSlice.name,
  bizService.inviteAction,
  ({ dispatch }) => {
    dispatch(getlistUserInvites());
  }
);

const closeGroupingInvite = createServiceCall<
  InviteActionServiceResponse,
  InviteActionServiceRequest
>(userSlice.name, bizService.inviteAction, ({ payload, dispatch }) => {});

const selectProfiles = createServiceCall<
  SelectProfilesServiceResponse,
  SelectProfilesServiceRequest
>(userSlice.name, profileService.selectProfiles, ({ response: { profiles }, dispatch }) => {
  const data: any = transformSearchProfiles(profiles);
  // @ts-ignore
  dispatch(setFoundProfiles(data));
});

export const useProfiler = (): {
  userProfile: Profile | null;
  userProfiles: { [key: string]: Profile };
  pinnedItems: TransformedPinnedItems;
  selectedProfile: Profile | undefined;
  currentProfile: Profile | undefined;
  foundProfiles: TSearchProfiles[];
  invitesList: ObjectInvite[] | null;
  uploadAvatarUrl: string | null;

  listUserInvites: { [key: string]: TUserInvite };
  profilesList: Profile[] | null;
  listMembers: { [key: string]: { profiles: { profiles: TProfile[] } } };
  userPermissions: {
    [key: string]: string;
  } | null;
  invitesLists: { [key: string]: TObjectInvite[] };
  listUserAvatars: Avatars | null;
  tooltipsList: string[];
  listRoles: { [key: string]: { id: string; roles: { roles: IAMRoleStrings[] } }[] };
  getMyProfile: () => void;
  getListToolTips: () => void;
  getWelcomeProfile: (payload: object) => void;
  getProfile: (payload: GetProfileServiceRequest) => void;
  getCurrentProfile: (payload: string) => void;
  updateProfileName: (payload: {
    names: {
      firstName: string;
      lastName: string;
      nickName: string;
    };
    birthDate: Date | null;
  }) => void;
  changeProfileStatus: (payload: ChangeProfileStatusServiceRequest) => void;
  getListProfiles: (payload: string[]) => void;
  finishToolTips: (payload: ToolTips) => void;
  selectProfiles: (payload: SelectProfilesServiceRequest) => void;
  sendInvitesByEmails: (payload: { id: string; type: Entity_Type; emails: string[] }) => void;
  getlistUserInvites: () => void;
  acceptUserInvite: (payload: InviteActionServiceRequest & { entity?: Entity }) => void;
  closeUserInvite: (payload: InviteActionServiceRequest) => void;
  closeGroupingInvite: (payload: { inviteId: string; action: InviteAction }) => void;
  getEntityInvitesList: (payload: { id: string; type: Entity_Type }) => void;
  pinEntity: (payload: { entityId: string; entityType: Entity_Type }) => void;
  unpinEntity: (payload: { entityId: string; entityType: Entity_Type }) => void;
  profileId: string | undefined | null;
  setProfileId: (
    newValue: string | null | undefined,
    updateType?: UrlUpdateType | undefined
  ) => void;
} => {
  const dispatch = useAppDispatch();
  const [profileId, setProfileId] = useQueryParam('profileId', StringParam);

  return {
    profileId,
    setProfileId,
    userProfile: useAppSelector((state) => state.profiler.user.profile),
    pinnedItems: useAppSelector((state) => state.profiler.user.pinnedItems),
    selectedProfile: useAppSelector((state) => state.profiler.user.selectedProfile),
    currentProfile: useAppSelector((state) => state.profiler.user.currentProfile),
    uploadAvatarUrl: useAppSelector((state) => state.profiler.user.uploadAvatarUrl),
    foundProfiles: useAppSelector((state) => state.profiler.user.searchProfiles),
    invitesList: useAppSelector((state) => state.profiler.user.invitesList),
    listUserInvites: useAppSelector((state) => state.profiler.user.listUserInvites),
    profilesList: useAppSelector((state) => state.profiler.user.listProfiles),
    listMembers: useAppSelector((state) => state.profiler.user.listMembers),
    userPermissions: useAppSelector((state) => state.profiler.user.userPermissions),
    listUserAvatars: useAppSelector((state) => state.profiler.user.userAvatars),
    tooltipsList: useAppSelector((state) => state.profiler.user.tooltipsList),
    invitesLists: useAppSelector((state) => state.profiler.user.invitesLists),
    userProfiles: useAppSelector((state) => state.profiler.user.userProfiles),
    listRoles: useAppSelector((state) => state.profiler.user.listRoles),
    pinEntity: useCallback(
      (payload) => {
        dispatch(
          pinEntity(
            new PinItemRequest({
              pin: new PinnedItem({
                entity: new Entity({
                  id: new UUID({ value: payload.entityId }),
                  type: payload.entityType,
                }),
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    unpinEntity: useCallback(
      (payload) => {
        dispatch(
          unpinEntity(
            new UnpinItemRequest({
              entity: new Entity({
                id: new UUID({ value: payload.entityId }),
                type: payload.entityType,
              }),
            })
          )
        );
      },
      [dispatch]
    ),

    getEntityInvitesList: useCallback(
      (payload) => {
        dispatch(
          getEntityInvitesList(
            new ListInvitesRequest({
              entity: new Entity({
                id: new UUID({ value: payload.id }),
                type: payload.type,
              }),
            })
          )
        );
      },
      [dispatch]
    ),

    // We use this method to init app, so to cut down unnecessary requests we check for tokens in advance
    getMyProfile: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getMyProfile());
      }
    }, [dispatch]),
    getListToolTips: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getListToolTips());
      }
    }, [dispatch]),
    finishToolTips: useCallback(
      (payload) => {
        dispatch(
          finishToolTips(
            new FinishToolTipsRequest({
              toolTips: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    getWelcomeProfile: useCallback(
      (payload) => {
        const tokens = JWT.getJWTTokens();
        if (tokens) {
          dispatch(getWelcomeProfile(payload));
        }
      },
      [dispatch]
    ),
    getProfile: useCallback(
      (payload) => {
        dispatch(getProfile(payload));
      },
      [dispatch]
    ),
    getCurrentProfile: useCallback(
      (payload) => {
        dispatch(
          getCurrentProfile(
            new GetProfileRequest({
              profileId: new UUID({ value: payload }),
            })
          )
        );
      },
      [dispatch]
    ),
    updateProfileName: useCallback(
      (payload) => {
        const data = new UpdateProfilePersonalInfoRequest({
          names: payload.names,
          birthDate: Timestamp.fromDate(
            // @ts-ignore
            payload.birthDate !== '' ? new Date(payload.birthDate) : new Date()
          ),
        });
        dispatch(updateProfileName(data));
      },
      [dispatch]
    ),
    changeProfileStatus: useCallback(
      (payload) => dispatch(changeProfileStatus(payload)),
      [dispatch]
    ),
    getListProfiles: useCallback(
      (payload) => {
        dispatch(
          getListProfiles(
            new ListProfilesRequest({
              profiles: new UUIDS({
                values: payload,
              }),
            })
          )
        );
      },
      [dispatch]
    ),

    selectProfiles: useCallback(
      (payload) => {
        dispatch(selectProfiles(payload));
      },
      [dispatch]
    ),

    sendInvitesByEmails: useCallback(
      (payload) => {
        dispatch(
          sendInvites(
            new SendInvitesRequest({
              entity: {
                id: new UUID({ value: payload.id }),
                type: payload.type,
              },
              recipients: {
                case: 'emails',
                value: new Strings({
                  values: payload.emails,
                }),
              },
            })
          )
        );
      },
      [dispatch]
    ),
    acceptUserInvite: useCallback(
      (payload) => {
        dispatch(acceptUserInvite(payload));
      },
      [dispatch]
    ),
    closeUserInvite: useCallback(
      (payload) => {
        dispatch(closeUserInvite(payload));
      },
      [dispatch]
    ),
    closeGroupingInvite: useCallback(
      (payload) => {
        dispatch(
          closeGroupingInvite(
            new InviteActionRequest({
              inviteId: new UUID({ value: payload.inviteId }),
              action: payload.action,
            })
          )
        );
      },
      [dispatch]
    ),
    getlistUserInvites: useCallback(() => {
      dispatch(getlistUserInvites());
    }, [dispatch]),
  };
};
