import Container from '@mui/material/Container';
import { Page } from '@components/Page/Page';
import React from 'react';
import SupplyList from '@pages/Marketplace/Dashboards/SupplyList';
import { DemandsList } from '@pages/Marketplace/Dashboards/DemandsList';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import UpdateDashboardInfoForm from '@pages/Marketplace/ManageDemands/UpdateDashboardInfoForm';
import { useParams } from 'react-router-dom';
import { useGetDashboard } from '@services/queries/MarketplaceQueries';

const displayContent = {
  demandsList: <DemandsList />,
  supplyList: <SupplyList />,
};

const KanbanBoard = () => {
  const { dashboardId } = useParams();
  const { data } = useGetDashboard(dashboardId as string);
  const [display, setDisplay] = React.useState<'demandsList' | 'supplyList'>('demandsList');

  return (
    <Page description="Kanbanboard" title="Kanbanboard">
      <Container>
        {data?.kanbanBoard && <UpdateDashboardInfoForm dashboard={data.kanbanBoard} />}
        <Box display="flex" gap={1} mb={2}>
          <Chip
            variant={display === 'demandsList' ? 'outlined' : 'filled'}
            onClick={() => setDisplay('demandsList')}
            label="Demands"
          />
          <Chip
            variant={display === 'supplyList' ? 'outlined' : 'filled'}
            onClick={() => setDisplay('supplyList')}
            label="Supplies"
          />
        </Box>
        {displayContent[display]}
      </Container>
    </Page>
  );
};
export default KanbanBoard;
