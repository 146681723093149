import { getConnectClient } from '@services/api/helpers';
import { SubscribeRequest } from '@proto/notificator/stream/v1/notifications_pb';
import { NotificationsService } from '@proto/notificator/stream/v1/notifications_connect';
import { useStreamStore } from '../../../zustand/subscribe';

const notificatorClient = getConnectClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_NOTIFICATION_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  NotificationsService
);

export const notificatorService = {
  async subscribe(): Promise<void> {
    const controller = new AbortController();
    const { signal } = controller;
    const { setIsConnected, addData, addBellNotification, setController } =
      useStreamStore.getState();

    setController(controller);

    try {
      const response = notificatorClient.subscribe(new SubscribeRequest(), { signal });

      setIsConnected(true);

      for await (const res of response) {
        if (res.payload.case === 'bell') {
          addBellNotification(res.payload.value);
        }
        addData(res);
      }
    } catch (e) {
      if (signal.aborted) {
        return;
      }
      setIsConnected(false);
      notificatorService.cancelStream();

      setTimeout(() => {
        notificatorService.subscribe();
      }, 2000);
    }
  },

  cancelStream(): void {
    const { controller, setIsConnected } = useStreamStore.getState();
    if (controller) {
      controller.abort();
      setIsConnected(false);
      useStreamStore.getState().setController(null);
    }
  },
};
