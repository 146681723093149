import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { CircularProgress, List, ListItem } from '@mui/material';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { useListDashboards } from '@services/queries/MarketplaceQueries';

const ManageDashboardsList = () => {
  const { data, isLoading, error } = useListDashboards();

  if (isLoading) <CircularProgress />;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography component="h5" color="inherit">
        Dashboards
      </Typography>

      {!data?.dashboards?.dashboards.length ? (
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography>
            One place to track all your active contracts, applications, and interviews.
          </Typography>
          <CreateDashboardButton />
        </Box>
      ) : (
        <List>
          {data.dashboards.dashboards.map((dashboard) => (
            <ListItem key={dashboard.dashboardId?.value}>
              <Link className="NavLink" to={`/${dashboard?.dashboardId?.value}`}>
                <Typography>{dashboard.title}</Typography>
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default ManageDashboardsList;
