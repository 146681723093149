import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import SidebarProfileSettings from '@pages/Main/SidebarProfileSettings/SidebarProfileSettings';
import ManageDashboardsList from '@pages/Marketplace/Dashboards/ManageDashboardsList';
import ListAppliedDemands from '@pages/Main/MainPage/ListAppliedDemands';
import { useListEvaluations } from '@services/queries/MarketplaceQueries';
import ListEvaluation from '@pages/Main/MainPage/ListEvaluations2';

const MainPage = () => {
  const { data } = useListEvaluations();

  return (
    <Container>
      {/* <Chat/> */}
      <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box display="flex" gap={3} flexDirection="column" width={{ xs: '100%', md: '25%' }}>
          <SidebarProfileSettings />
        </Box>
        <Box display="flex" gap={2} flexDirection="column" width={{ xs: '100%', md: '25%' }}>
          <ManageDashboardsList />
        </Box>
        <Box width={{ xs: '100%', md: '35%' }}>
          <ListAppliedDemands />
        </Box>
        {data?.sortedEvalutions && (
          <ListEvaluation tags={data.tags} evaluations={data?.sortedEvalutions} />
        )}
      </Box>
    </Container>
  );
};

export default MainPage;
