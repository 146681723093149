import React, { Suspense } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import Button from '@mui/material/Button';
import {
  useApplyAsInterviewer,
  useGetInterviewerrAvailability,
  useListAppliedDemands,
  useListFeedItemsByDemand,
} from '@services/queries/MarketplaceQueries';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { demandsTypes } from '@pages/Marketplace/Feed/FeedPage';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import CustomAvatar from '@components/Avatar/Avatar';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import CalendarComponentForInterviewer from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForInterviewer';
import { useDialog } from '@components/hooks/useDialog';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';

const PreAppliedDemandCard = ({
  demand,
  isPreApplied,
  showActiveState,
}: {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
}) => {
  const { demandIdFromUrl } = useMarketplace();
  const demandId = getDemandId(demand);
  const { mutate: applyAsInterviewer } = useApplyAsInterviewer();
  const entity = demandIdFromUrl
    ? new Entity({
        id: new UUID({ value: demandIdFromUrl.demandId }),
        type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
      })
    : undefined;

  const { data: feedByContract } = useListFeedItemsByDemand(entity);
  const [matchedTags, setMatchedTags] = React.useState<any>(null);
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { data: appliedDemands } = useListAppliedDemands();

  const { data: profile } = useGetMyProfile();
  const { data: slots } = useGetInterviewerrAvailability({
    case: 'interviewerId',
    value: profile?.profile?.profileId as UUID,
  });
  const isHaveAvailibilitySlots = !!slots && slots?.slots?.length > 0;

  const recommendations =
    feedByContract?.recommendations?.recommendations[demandId?.value as string]?.recommendations;

  const isApplied =
    appliedDemands?.demands?.applied?.interviews &&
    Object.values(appliedDemands?.demands?.applied?.interviews)?.some((interview) => {
      return (interview.entity.value as Interview)?.interviewId?.value === demandId?.value;
    });

  const isApproved =
    appliedDemands?.demands?.approved?.interviews &&
    Object.values(appliedDemands?.demands?.approved?.interviews)?.some((interview) => {
      return (interview.entity.value as Interview)?.interviewId?.value === demandId?.value;
    });

  const suppliers =
    recommendations &&
    Object.values(recommendations).map((supplier) => {
      return (
        <Box>
          {supplier?.suppliers?.map((sup) => {
            return (
              <Box
                onClick={() =>
                  setMatchedTags({
                    tags: sup.matchedTags?.tags,
                    traits: sup?.traits,
                  })
                }
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
              >
                <CustomAvatar
                  alt={sup?.profile?.name?.nickName || 'User'}
                  avatarId={sup?.profile?.avatar}
                  profileId={sup?.profile?.profileId?.value}
                />
                <Typography>{sup?.profile?.name?.nickName || '?rec supplier name'}</Typography>
              </Box>
            );
          })}
        </Box>
      );
    });

  return (
    <Box className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}>
      <Card>
        <Box display="flex" gap={1} flexDirection="column" justifyContent="center" mb={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2} alignItems="center">
              <Typography fontSize="14px" fontWeight="bold">
                {demand?.entity?.case?.toUpperCase()}
              </Typography>
              {demand.entity.value?.tags?.tags
                .filter((tag) => tag.tagType === TagType.ROLE)
                .map((tag) => {
                  return <Chip variant="outlined" label={tag.value} />;
                })}
            </Box>
            <Typography fontSize="14px">
              {format(new Date(demand.entity.value?.createdAt?.toDate() || ''), 'dd/MM/yyyy')}
            </Typography>
          </Box>
          <Divider />
          {suppliers && (
            <Box display="flex" gap={1} flexDirection="column">
              <Typography fontWeight="bold">This user has eligible to this demand</Typography>
              {suppliers}
              <Divider />
            </Box>
          )}

          <Box display="flex" gap={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={1}>
              <DemandTraits
                matches={matchedTags?.traits}
                fontSize="13px"
                gap={2}
                demand={demand.entity.value}
              />
              {demand.entity.value?.tags?.tags && (
                <Tags
                  matchedTags={matchedTags?.tags}
                  showType
                  tags={demand.entity.value?.tags?.tags}
                />
              )}
            </Box>
          </Box>
          {demand?.entity?.case === 'contract' &&
            demand?.entity?.value.description &&
            HTMLReactParser(
              stateToHTML(convertFromRaw(JSON.parse(demand?.entity.value.description)))
            )}
          {demand?.entity?.case === 'interview' &&
            demand?.entity.value.requirements &&
            HTMLReactParser(
              stateToHTML(convertFromRaw(JSON.parse(demand?.entity.value.requirements)))
            )}
        </Box>
        <Box display="flex">
          {demand?.entity?.case === 'interview' && (
            <Button
              disabled={isApplied || isApproved}
              variant="contained"
              onClick={() => {
                if (!isHaveAvailibilitySlots) handleOpen();
                if (isHaveAvailibilitySlots)
                  applyAsInterviewer(demand?.entity?.value?.interviewId?.value || '');
              }}
            >
              {isApplied || isApproved ? 'You have been applied' : 'Apply'}
            </Button>
          )}
        </Box>
        {isPreApplied && demand?.entity?.case === 'contract' && (
          <ContractorSelect contractId={demandId?.value} />
        )}
      </Card>
      <Suspense>
        <CalendarComponentForInterviewer handleClose={handleClose} isOpen={isOpen} />
      </Suspense>
    </Box>
  );
};

export default PreAppliedDemandCard;
