import Box from '@mui/material/Box';
import React from 'react';
import SidebarProfileNameForm from '@pages/Main/SidebarProfileSettings/SidebarProfileNameForm';
import SidebarProfileTraitsForm from '@pages/Main/SidebarProfileSettings/SidebarProfileTraitsForm';
import SidebarTags from '@pages/Main/SidebarProfileSettings/SidebarTags';
import UserInvitesList from '@components/Invites/UserInvitesList';
import ListMemberships from '@pages/Main/MainPage/ListMemberships';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';
import CustomAvatar from '@components/Avatar/Avatar';

const SidebarProfileSettings = () => {
  const { data: userProfile } = useGetMyProfile();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column" gap={1}>
        <UserInvitesList />
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={2}>
            <CustomAvatar
              alt={userProfile?.profile?.name?.nickName || 'User'}
              avatarId={userProfile?.profile?.avatar}
              profileId={userProfile?.profile?.profileId?.value}
            />
            <SidebarProfileNameForm />
          </Box>
          <SidebarProfileTraitsForm />
          <SidebarTags />
          <ListMemberships />
          {/* {userProfile?.profile?.profileId && ( */}
          {/*    <Products id={userProfile?.profile?.profileId?.value} type={Entity_Type.USER}/> */}
          {/* )} */}
          {/* <ListUserAchievements/> */}
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarProfileSettings;
