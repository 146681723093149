import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  PinItemRequest,
  PinItemResponse,
  Profile,
  ProfileName,
  SelectProfilesRequest,
  SelectProfilesResponse,
  Traits,
  UnpinItemRequest,
  UnpinItemResponse,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from '@proto/profiler/user/v1/profile_pb';

import { getConnectClient, makeRequest, PlainMsg } from '@services/api/helpers';
import { ObjectInvite } from '@proto/profiler/invite/v1/invite_pb';
import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ListToolTipsRequest,
  ListToolTipsResponse,
} from '@proto/profiler/user/v1/tool_tips_pb';
import {
  ActivateAvatarRequest,
  ActivateAvatarResponse,
  DeleteAvatarRequest,
  DeleteAvatarResponse,
  GetAvatarUploadUrlRequest,
  GetAvatarUploadUrlResponse,
  ListAvatarsRequest,
  ListAvatarsResponse,
} from '@proto/profiler/avatars/v1/avatar_pb';

import { ProfileAPI } from '@proto/api/profiler/v1/profile_connect';

import { AvatarAPI } from '@proto/api/profiler/v1/avatar_connect';
import { ToolTipsAPI } from '@proto/api/profiler/v1/tool_tips_connect';
import { IAMAPI } from '@proto/api/iam/v1/iam_connect';

export type GetMyProfileServiceRequest = PlainMsg<GetMyProfileRequest>;
export type GetMyProfileServiceResponse = PlainMsg<GetMyProfileResponse>;
export type UpdateProfilePersonalInfoServiceResponse = PlainMsg<UpdateProfilePersonalInfoResponse>;
export type UpdateProfilePersonalInfoServiceRequest = PlainMsg<UpdateProfilePersonalInfoRequest>;
export type ChangeProfileStatusServiceResponse = PlainMsg<ChangeProfileStatusResponse>;
export type ChangeProfileStatusServiceRequest = PlainMsg<ChangeProfileStatusRequest>;
export type UpdateProfileTraitsServiceResponse = PlainMsg<UpdateProfileTraitsResponse>;
export type UpdateProfileTraitsServiceRequest = PlainMsg<UpdateProfileTraitsRequest>;

export type ListProfilesServiceRequest = PlainMsg<ListProfilesRequest>;
export type ListProfilesServiceResponse = PlainMsg<ListProfilesResponse>;

export type GetProfileServiceRequest = PlainMsg<GetProfileRequest>;
export type GetProfileServiceResponse = PlainMsg<GetProfileResponse>;
export type SelectProfilesServiceRequest = PlainMsg<SelectProfilesRequest>;
export type SelectProfilesServiceResponse = PlainMsg<SelectProfilesResponse>;

export type GetAvatarUploadUrlServiceRequest = PlainMsg<GetAvatarUploadUrlRequest>;
export type GetAvatarUploadUrlServiceResponse = PlainMsg<GetAvatarUploadUrlResponse>;
export type DeleteAvatarServiceRequest = PlainMsg<DeleteAvatarRequest>;
export type DeleteAvatarServiceResponse = PlainMsg<DeleteAvatarResponse>;
export type ListAvatarsServiceRequest = PlainMsg<ListAvatarsRequest>;
export type ListAvatarsServiceResponse = PlainMsg<ListAvatarsResponse>;
export type ActivateAvatarServiceRequest = PlainMsg<ActivateAvatarRequest>;
export type ActivateAvatarServiceResponse = PlainMsg<ActivateAvatarResponse>;
export type PinItemServiceRequest = PlainMsg<PinItemRequest>;
export type PinItemServiceResponse = PlainMsg<PinItemResponse>;
export type UnPinItemServiceRequest = PlainMsg<UnpinItemRequest>;
export type UnPinItemServiceResponse = PlainMsg<UnpinItemResponse>;

export type ListToolTipsServiceRequest = PlainMsg<ListToolTipsRequest>;
export type ListToolTipsServiceResponse = PlainMsg<ListToolTipsResponse>;
export type FinishToolTipsServiceRequest = PlainMsg<FinishToolTipsRequest>;
export type FinishToolTipsServiceResponse = PlainMsg<FinishToolTipsResponse>;

export type TProfile = PlainMsg<Profile>;
export type TProfileName = PlainMsg<ProfileName>;
export type TProfileTraits = PlainMsg<Traits>;
// export type TProfileSocialUrls = PlainMsg<SocialUrls>;
export type TInvite = PlainMsg<ObjectInvite>;

export const profileClient = getConnectClient<typeof ProfileAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProfileAPI
);

export const avatarClient = getConnectClient<typeof AvatarAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AvatarAPI
);

export const toolTipsClient = getConnectClient<typeof ToolTipsAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ToolTipsAPI
);
export const iAMClient = getConnectClient<typeof IAMAPI>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_APIGW_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  IAMAPI
);

export const profileService = {
  getMyProfile(): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, new GetMyProfileRequest());
  },
  getAvatarUpload(
    request: GetAvatarUploadUrlServiceRequest
  ): Promise<GetAvatarUploadUrlServiceResponse> {
    return makeRequest(avatarClient.getAvatarUploadUrl, request);
  },
  pinItem(request: PinItemServiceRequest): Promise<PinItemServiceResponse> {
    return makeRequest(profileClient.pinItem, request);
  },
  unPinItem(request: UnPinItemServiceRequest): Promise<UnPinItemServiceResponse> {
    return makeRequest(profileClient.unpinItem, request);
  },

  deleteAvatar(request: DeleteAvatarServiceRequest): Promise<DeleteAvatarServiceResponse> {
    return makeRequest(avatarClient.deleteAvatar, request);
  },
  listAvatars(request: ListAvatarsServiceRequest): Promise<ListAvatarsServiceResponse> {
    return makeRequest(avatarClient.listAvatars, request);
  },

  activateAvatar(request: ActivateAvatarServiceRequest): Promise<ActivateAvatarServiceResponse> {
    return makeRequest(avatarClient.activateAvatar, request);
  },
  getWelcomeProfile(request: GetMyProfileServiceRequest): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, request);
  },
  getProfile(request: GetProfileServiceRequest): Promise<GetProfileServiceResponse> {
    return makeRequest<GetProfileRequest, GetProfileResponse>(profileClient.getProfile, request);
  },

  listProfiles(request: ListProfilesServiceRequest): Promise<ListProfilesServiceResponse> {
    return makeRequest(profileClient.listProfiles, request);
  },

  updateProfileName(
    request: UpdateProfilePersonalInfoServiceRequest
  ): Promise<UpdateProfilePersonalInfoServiceResponse> {
    return makeRequest<UpdateProfilePersonalInfoRequest, UpdateProfilePersonalInfoResponse>(
      profileClient.updateProfilePersonalInfo,
      request
    );
  },

  changeProfileStatus(
    request: ChangeProfileStatusServiceRequest
  ): Promise<ChangeProfileStatusServiceResponse> {
    return makeRequest<ChangeProfileStatusRequest, ChangeProfileStatusResponse>(
      profileClient.changeProfileStatus,
      request
    );
  },
  selectProfiles(request: SelectProfilesServiceRequest): Promise<SelectProfilesServiceResponse> {
    return makeRequest<SelectProfilesRequest, SelectProfilesResponse>(
      profileClient.selectProfiles,
      request
    );
  },

  updateTraits(
    request: UpdateProfileTraitsServiceRequest
  ): Promise<UpdateProfileTraitsServiceResponse> {
    return makeRequest<UpdateProfileTraitsRequest, UpdateProfileTraitsResponse>(
      profileClient.updateProfileTraits,
      request
    );
  },
  listToolTips(): Promise<ListToolTipsServiceResponse> {
    return makeRequest<ListToolTipsRequest, ListToolTipsResponse>(
      toolTipsClient.listToolTips,
      new ListToolTipsRequest()
    );
  },
  finishTooltips(request: FinishToolTipsServiceRequest): Promise<FinishToolTipsServiceResponse> {
    return makeRequest<FinishToolTipsRequest, FinishToolTipsResponse>(
      toolTipsClient.finishToolTips,
      request
    );
  },
};
