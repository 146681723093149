// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file api/iam/v1/membership.proto (package api.iam.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Profiles } from '../../../profiler/user/v1/profile_pb.js';

/**
 * @generated from message api.iam.v1.ListMembersResponse
 */
export class ListMembersResponse extends Message<ListMembersResponse> {
  /**
   * @generated from field: map<string, profiler.user.v1.Profiles> members = 1;
   */
  members: { [key: string]: Profiles } = {};

  constructor(data?: PartialMessage<ListMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'api.iam.v1.ListMembersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'members',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: Profiles },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse {
    return new ListMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined,
    b: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse, a, b);
  }
}
