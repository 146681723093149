import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '@components/Form';
import {
  createInterviewForContractResolverSchema,
  FieldNames,
} from '@pages/Marketplace/ManageDemands/settings';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { tagsLabel, TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import { Chip, Typography } from '@mui/material';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { useCreateInterviewForContract } from '@services/queries/MarketplaceQueries';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';

type CreateContractFormProps = {
  handleCloseModal: () => void;
  dashboardId: string;
  contractId: string;
  demand: Demand;
  isOpen: boolean;
};

const CreateInterviewForContractForm = (props: CreateContractFormProps) => {
  const { handleCloseModal, dashboardId, contractId, isOpen, demand } = props;
  const { mutate: createInterviewForContract } = useCreateInterviewForContract();
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(demand?.entity.value?.tags?.tags || []));
  }, [demand?.entity.value?.tags?.tags]);

  return (
    <Dialog onClose={handleCloseModal} open={isOpen}>
      <Box
        sx={{
          width: { xs: '300px', sm: '400px', md: '600px' },
          backgroundColor: '#3f3d50',
        }}
        py={5}
        px={{ xs: 3 }}
        display="flex"
        flexDirection="column"
        gap={1}
      >
        <Form
          width="100%"
          display="flex"
          alignSelf="center"
          flexDirection="column"
          formProps={{
            defaultValues: {
              dashboardId,
              contractId,
              requirements: '',
            },
          }}
          submitHandler={(data) => {
            createInterviewForContract(data);
            handleCloseModal();
          }}
          resolverSchema={createInterviewForContractResolverSchema}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="subtitle1" textAlign="center">
              Create interview for contract
            </Typography>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <Typography fontWeight="bold">
                Outline any specific requirements for the position:
              </Typography>
              <WysiwygController name={FieldNames.REQUIREMENTS} />
            </Box>
            <DemandTraits gap={2} demand={demand} />
            {tagByType.map(([type, tags]) => (
              <Box display="flex" alignItems="center" gap={1} key={type}>
                <Typography>{tagsLabel[type]}:</Typography>
                <Box display="flex" gap={1}>
                  {tags.map((tag) => (
                    <Chip key={tag.value} label={tag.value} variant="outlined" />
                  ))}
                </Box>
              </Box>
            ))}
            <LoadingButton type="submit" variant="outlined">
              Create interview
            </LoadingButton>
          </Box>
        </Form>
      </Box>
    </Dialog>
  );
};

export default CreateInterviewForContractForm;
