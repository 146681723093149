import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Section, TCustomDemands } from '@store/marketplace/helpers';
import { isInvalidTimestamp } from '@utils/invalidTimestamp';

export interface TFeedList {
  contracts: Record<string, Demand>;
  interviews: Record<string, Demand>;
}

export interface SortedDemandsBySupply {
  all: TFeedList;
  applied: TFeedList;
  approved: TFeedList;
  released: TFeedList;
  rejected: TFeedList;
}

export const transformFeedItems = (feedItems: Demand[]): TFeedList => {
  return feedItems.reduce(
    (acc: TFeedList, item: Demand) => {
      if (item.entity.case === 'contract' && item.entity.value) {
        const contract = item.entity.value as Contract;
        acc.contracts[contract?.contractId?.value as string] = item;
      }
      if (item.entity.case === 'interview' && item.entity.value) {
        const interview = item.entity.value as Interview;
        acc.interviews[interview?.interviewId?.value as string] = item;
      }
      return acc;
    },
    { contracts: {}, interviews: {} }
  );
};

export function sortDemandsBySupply(data: TFeedList): SortedDemandsBySupply {
  const categories: SortedDemandsBySupply = {
    all: { contracts: {}, interviews: {} },
    applied: { contracts: {}, interviews: {} },
    approved: { contracts: {}, interviews: {} },
    released: { contracts: {}, interviews: {} },
    rejected: { contracts: {}, interviews: {} },
  };

  const addToCategory = (
    category: keyof SortedDemandsBySupply,
    type: keyof TCustomDemands,
    id: string,
    entry: any
  ): void => {
    if (!categories[category][type][id]) {
      categories[category][type][id] = { ...entry, supply: { list: { list: [] } } };
    }
    // @ts-ignore
    categories[category][type][id].supply?.view?.value?.list.push(
      entry?.supply?.view?.value?.list[0]
    );
  };

  if (!Object.keys(data).length) return {} as SortedDemandsBySupply;

  Object.entries(data)?.forEach(([sectionType, sectionContent]: [string, Section]) => {
    Object.entries(sectionContent).forEach(([id, details]: [string, any]) => {
      details?.supply?.view?.value?.list.forEach((entity: any) => {
        addToCategory('all', sectionType as keyof TCustomDemands, id, {
          ...details,
          supply: { list: { list: [entity] } },
        });
        if (isInvalidTimestamp(`${entity?.releasedAt?.toJsonString()}`)) {
          addToCategory('released', sectionType as keyof TCustomDemands, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.rejectedAt?.toJsonString()}`)) {
          addToCategory('rejected', sectionType as keyof TCustomDemands, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.approvedAt?.toJsonString()}`)) {
          addToCategory('approved', sectionType as keyof TCustomDemands, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        } else if (isInvalidTimestamp(`${entity?.appliedAt?.toJsonString()}`)) {
          addToCategory('applied', sectionType as keyof TCustomDemands, id, {
            ...details,
            supply: { list: { list: [entity] } },
          });
        }
      });
    });
  });

  return categories;
}
