import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { Suspense, useState } from 'react';
import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
  FIELD_LABELS,
  FieldNames,
  getDefaultValues,
  myProfileTraitsFormSchema,
} from '@pages/ProfileSettings/ProfileTraitsForm/config';
import Grid from '@mui/material/Grid';
import { CheckboxController } from '@components/Form/controllers/CheckboxController/CheckboxController';
import CheckIcon from '@mui/icons-material/Check';
import { motion } from 'framer-motion';
import { useGetMyProfile, useUupdateProfileTraits } from '@services/queries/ProfilesQueries';
import { Status } from '@proto/grpc/type/v1/status_pb';
import { useDialog } from '@components/hooks/useDialog';
import CalendarComponentForInterviewer from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponentForInterviewer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const SidebarProfileTraitsForm = () => {
  const { mutate: updateTraits, error } = useUupdateProfileTraits();
  const [isEdited, setIsEdited] = useState(true);
  const { data: userProfile } = useGetMyProfile();
  const { isOpen, handleOpen, handleClose } = useDialog();

  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
      return;
    }
    if (
      userProfile?.profile?.traits?.rate !== data.traits?.rate ||
      userProfile?.profile?.traits?.experience !== data.traits?.experience ||
      userProfile?.profile?.traits?.capacity !== data.traits?.capacity
    ) {
      updateTraits(data);
    }
    setIsEdited(!isEdited);
  };

  return userProfile ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile?.profile?.traits) }}
      resolverSchema={myProfileTraitsFormSchema}
      submitHandler={handleSubmit}
    >
      <Suspense>
        <CalendarComponentForInterviewer handleClose={handleClose} isOpen={isOpen} />
      </Suspense>
      <Box display="flex" flexDirection="column" gap={1}>
        {isEdited ? (
          <motion.div
            key={!isEdited ? 'show' : 'hide'}
            initial={{
              opacity: isEdited ? 0 : 1,
              x: isEdited ? -10 : 0,
            }}
            animate={{
              opacity: isEdited ? 1 : 0,
              x: isEdited ? 0 : -10,
            }}
            transition={{ duration: 0.3 }}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" columnGap={2} rowGap={1} flexWrap="wrap">
                <Box display="flex" alignItems="center">
                  <Tooltip placement="right" title="years">
                    <Typography fontSize="13px">
                      Experience: {userProfile?.profile?.traits?.experience}+
                    </Typography>
                  </Tooltip>
                </Box>

                <Box display="flex" alignItems="center">
                  <Tooltip placement="right" title="hours per week">
                    <Typography fontSize="13px">
                      Capacity: {userProfile?.profile?.traits?.capacity}{' '}
                    </Typography>
                  </Tooltip>
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                  <Typography fontSize="13px">
                    Rate: ${userProfile?.profile?.traits?.rate}
                  </Typography>
                </Box>

                {userProfile?.profile?.traits?.interviewer === Status.TRUE && (
                  <Box display="flex" alignItems="center" gap={0.5} onClick={handleOpen}>
                    <Typography lineHeight="16px" fontSize="13px">
                      Interviewer
                    </Typography>
                    <CalendarMonthIcon sx={{ cursor: 'pointer', width: '16px', height: '16px' }} />
                  </Box>
                )}
              </Box>
              <Box>
                <Tooltip title="Edit traits" placement="top-start">
                  <LoadingButton type="submit">
                    <EditIcon sx={{ width: '18px', height: '18px' }} />
                  </LoadingButton>
                </Tooltip>
              </Box>
            </Box>
          </motion.div>
        ) : (
          <motion.div
            key={!isEdited ? 'show' : 'hide'}
            initial={{
              opacity: !isEdited ? 0 : 1,
              x: !isEdited ? -10 : 0,
            }}
            animate={{
              opacity: !isEdited ? 1 : 0,
              x: !isEdited ? 0 : -10,
            }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={2} mb={2} className="fifth-step">
              <Grid item xs={12} display="flex" flexDirection="column">
                <LoadingButton type="submit">
                  <CheckIcon sx={{ width: '18px', height: '18px' }} />
                </LoadingButton>
              </Grid>

              <Grid item xs={6}>
                <InputController
                  fullWidth
                  type="number"
                  name={`traits.${FieldNames.RATE}`}
                  label={FIELD_LABELS[FieldNames.RATE]}
                />
              </Grid>
              <Grid item xs={6}>
                <InputController
                  fullWidth
                  type="number"
                  name={`traits.${FieldNames.CAPACITY}`}
                  label={FIELD_LABELS[FieldNames.CAPACITY]}
                />
              </Grid>
              <Grid item xs={6}>
                <InputController
                  fullWidth
                  type="number"
                  name={`traits.${FieldNames.EXPIRIENCE}`}
                  label={FIELD_LABELS[FieldNames.EXPIRIENCE]}
                />
              </Grid>
              <Grid item xs={6}>
                <CheckboxController
                  labelProps={{ sx: { margin: 0 } }}
                  name={`traits.${FieldNames.INTERVIEWER}`}
                  label={FIELD_LABELS[FieldNames.INTERVIEWER]}
                />
              </Grid>
            </Grid>
          </motion.div>
        )}
      </Box>
    </Form>
  ) : null;
};

export default SidebarProfileTraitsForm;
