import { create } from 'zustand/react';
import { Bell } from '@proto/notificator/stream/v1/notifications_pb';

interface StreamState {
  controller: AbortController | null;
  data: any[];
  isConnected: boolean;
  bellNotifications: Bell[];
  clearBellNotifications: () => void;
  setController: (controller: AbortController | null) => void;
  addData: (newData: any) => void;
  addBellNotification: (newData: Bell) => void;
  clearData: () => void;
  setIsConnected: (status: boolean) => void;
}

export const useStreamStore = create<StreamState>((set) => ({
  data: [],
  controller: null,
  setController: (controller) => set({ controller }),
  isConnected: false,
  bellNotifications: [],
  addBellNotification: (newData) =>
    set((state) => ({ bellNotifications: [...state.bellNotifications, newData] })),
  clearBellNotifications: () => set({ bellNotifications: [] }),
  addData: (newData) => set((state) => ({ data: [...state.data, newData] })),
  clearData: () => set({ data: [] }),
  setIsConnected: (status) => set({ isConnected: status }),
}));
