import { useProfiler } from '@store/profiler';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useGetMyProfile } from '@services/queries/ProfilesQueries';

const profileActiveStatusSwitchLabel = 'Active profile';

export const ProfileActiveStatusSwitch = () => {
  const { changeProfileStatus } = useProfiler();
  const { data: userProfile } = useGetMyProfile();

  const handleChange = () => {
    changeProfileStatus({ active: !userProfile?.profile?.active });
  };

  return (
    <FormControlLabel
      label={profileActiveStatusSwitchLabel}
      control={<Switch onChange={handleChange} checked={userProfile?.profile?.active} />}
    />
  );
};
