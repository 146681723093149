import { TTag } from '@services/api/profiler/tags';
import { TCreateContractForm } from '@pages/Marketplace/Dashboards/CreateContracForm';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { getTagType } from '@store/profiler/helpers';
import { PlainMsg } from '@services/api/helpers';
import { TCreateInterviewForm } from '@pages/Marketplace/ManageDemands/settings';
import { TCreateProductForm } from '@components/CreateProductForm/CreateProductDialog';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { SupplyResponse } from '@proto/marketplace/demand/v1/supply_pb';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';

export type UpdateContractForm = {
  rate?: number | undefined;
  price?: number | undefined;
  capacity?: number | undefined;
  experience?: number;
  contractId?: UUID | undefined;
  interviewId?: string;
};

export interface DemandCombinedItem {
  type: string;
  createdAt: string;
  id: string;
  tags: { tags: TTag[] };
  traits: {
    rate?: number;
    capacity?: number;
    price?: number;
    experience: number;
  };
  description?: string;
  updatedAt: string;
  contractId?: string;
  interviewId?: string;
  requirements?: string;
  status: string;
  entity?: { value: Interview; case: 'interview' } | { value: Contract; case: 'contract' };
  supply?: SupplyResponse;
}

export type TCustomDemands = {
  contracts: { [contractId: string]: DemandCombinedItem };
  interviews: { [interviewId: string]: DemandCombinedItem };
};

export function convertDemands(demands: any[]): TCustomDemands {
  const customDemands: TCustomDemands = {
    contracts: {},
    interviews: {},
  };

  demands.forEach((demand) => {
    const demandCombinedItem: DemandCombinedItem = {
      ...demand,
    };

    if (demand.entity.case === 'interview') {
      customDemands.interviews[demand.entity.value.interviewId.value] = demandCombinedItem;
    } else if (demand.entity.case === 'contract') {
      customDemands.contracts[demand.entity.value.contractId.value] = demandCombinedItem;
    }
  });

  return customDemands;
}

// TODO add types
export function organizeDemands(items: any[]): TCustomDemands {
  return items?.reduce<TCustomDemands>(
    (acc, item) => {
      if (item?.interview) {
        const { interviewId } = item.interview;
        acc.interviews[interviewId] = {
          tags: item.interview.tags,
          createdAt: item.interview.createdAt,
          updatedAt: item.interview.updatedAt,
          traits: item.interview.traits,
          requirements: item.interview.requirements,
          id: interviewId,
          type: 'interview',
          status: item.status,
          supply: item.supply,
        };
      }

      if (item?.contract) {
        const { contractId } = item.contract;
        acc.contracts[contractId] = {
          tags: item.contract.tags,
          createdAt: item.contract.createdAt,
          updatedAt: item.contract.updatedAt,
          traits: item.contract.traits,
          id: contractId,
          type: 'contract',
          status: item.status,
          interviewId: item.contract.interviewId,
          description: item.contract.description,
          supply: item.supply,
        };
      }

      return acc;
    },
    { contracts: {}, interviews: {} }
  );
}

export const getTagsFromPayload = (
  tags: TCreateContractForm | TCreateInterviewForm | TCreateProductForm
) => {
  const tagsResult: Tag[] = [];
  Object.entries(tags).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.map((element) => {
        const attribute = new Tag({
          tagId: element.tagId,
          value: element.value,
          tagType: getTagType[key as keyof typeof getTagType],
          active: true,
        });
        return tagsResult.push(attribute);
      });
    }
  });
  return tagsResult;
};

interface Result {
  [key: string]: string[];
}

export function groupEntitiesByIds(entities: PlainMsg<any>[]): Result {
  const result: Result = {};

  entities.forEach((item) => {
    const entityType = `${item?.entity?.type}`;
    const entityId = `${item?.entity?.id?.value}`;

    if (!result[entityType]) {
      result[entityType] = [];
    }

    result[entityType].push(entityId);
  });

  return result;
}

export interface Section {
  [contractId: string]: DemandCombinedItem;
}

interface Entity {
  id: string;
  type: string;
}

interface Record {
  appliedAt: string;
  approvedAt: string;
  entity: Entity;
  rejectedAt: string;
  releasedAt: string;
}

interface FilteredEntities {
  applied: Record[];
  approved: Record[];
}

export const filterSuppliersByStatus = (records: Record[]): FilteredEntities | undefined => {
  if (!records) return undefined;
  return records.reduce<FilteredEntities>(
    (acc, record) => {
      const { appliedAt, approvedAt } = record;

      if (approvedAt !== '0001-01-01T00:00:00Z') {
        acc.approved.push(record);
      } else if (appliedAt !== '0001-01-01T00:00:00Z') {
        acc.applied.push(record);
      }

      return acc;
    },
    { applied: [], approved: [] }
  );
};
