import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import {
  bizService,
  ListOrgsServiceRequest,
  ListOrgsServiceResponse,
  SearchOrgsServiceRequest,
  SearchOrgsServiceResponse,
  UpdateOrgInfoServiceRequest,
  UpdateOrgInfoServiceResponse,
} from '@services/api/profiler/biz';
import { userSlice } from '@store/profiler/slices';
import { combineReducers } from '@reduxjs/toolkit';
import { orgSlices } from '@store/orgs/slices';
import { Org } from '@store/profiler/profiler';
import { useCallback } from 'react';
import {
  ListOrgsRequest,
  SearchOrgsRequest,
  UpdateOrgInfoRequest,
} from '@proto/profiler/biz/v1/org_pb';
import { squadsSlices } from '@store/squads/slices';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';

const OrgFilterParam = withDefault(ArrayParam, []);

const {
  actions: { setUserOrgs, setPinnedOrgs, setExploreOrgs, setCurrentOrg, setPaginationOrgs },
} = orgSlices;

const {
  actions: { deleteSelectedProfile },
} = userSlice;
export const orgsReducer = combineReducers({
  orgs: orgSlices.reducer,
});
export const getListUserOrgs = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  orgSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setUserOrgs(orgs));
  },
  ({ dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setUserOrgs({ orgs: undefined }));
  }
);
const getListPinOrgs = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  orgSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setPinnedOrgs(orgs));
  }
);

const searchOrgs = createServiceCall<SearchOrgsServiceResponse, SearchOrgsServiceRequest>(
  orgSlices.name,
  bizService.searchOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setExploreOrgs(orgs));
  }
);
const searchPaginationOrgs = createServiceCall<SearchOrgsServiceResponse, SearchOrgsServiceRequest>(
  orgSlices.name,
  bizService.searchOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setPaginationOrgs(orgs));
  }
);
export const getCurrentOrg = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  squadsSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setCurrentOrg(orgs));
  }
);

const updateOrgInfo = createServiceCall<UpdateOrgInfoServiceResponse, UpdateOrgInfoServiceRequest>(
  orgSlices.name,
  bizService.updateOrgInfo,
  ({ response, dispatch }) => {}
);

export const useOrgs = (): {
  listUserOrgs: Org[] | undefined;
  listExploreOrgs: Org[] | undefined;
  currentOrg: Org | undefined;
  orgsFilters: any[];
  queryParams: any;
  searchOrgs: (payload: { name?: string; industry_domain?: number[] }) => void;
  setOrgsFilters: (payload: string[]) => void;
  getCurrentOrg: (payload: string[]) => void;
  searchPaginationOrgs: (payload: Timestamp) => void;
  updateOrgInfo: (payload: { orgId: string; about: string }) => void;
} => {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useQueryParams({
    orgsFilters: OrgFilterParam,
  });

  return {
    listUserOrgs: useAppSelector((state) => state.orgsReducer.orgs.listUserOrgs),
    listExploreOrgs: useAppSelector((state) => state.orgsReducer.orgs.exploreOrgsList),
    currentOrg: useAppSelector((state) => state.orgsReducer.orgs.currentOrg),
    orgsFilters: useAppSelector((state) => state.orgsReducer.orgs.orgsFilters),
    queryParams: query,
    searchPaginationOrgs: useCallback(
      (payload) => {
        dispatch(
          searchPaginationOrgs(
            new SearchOrgsRequest({
              created: payload,
            })
          )
        );
      },
      [dispatch]
    ),

    setOrgsFilters: useCallback(
      (payload) => {
        if (payload) {
          setQuery((prevQuery) => {
            const newQuery = { ...prevQuery };
            if (payload) {
              newQuery.orgsFilters = payload;
            }
            return newQuery;
          });
        }
      },
      [setQuery]
    ),

    searchOrgs: useCallback(
      (payload) => {
        if (payload.name !== undefined) {
          return dispatch(
            searchOrgs(
              new SearchOrgsRequest({
                name: payload.name,
              })
            )
          );
        }
        if (payload.industry_domain !== undefined) {
          return dispatch(
            searchOrgs(
              new SearchOrgsRequest({
                industryDomanIds: payload.industry_domain,
              })
            )
          );
        }

        return dispatch(searchOrgs(new SearchOrgsRequest({})));
      },
      [dispatch]
    ),
    getCurrentOrg: useCallback(
      (payload) =>
        dispatch(
          getCurrentOrg(
            new ListOrgsRequest({
              orgIds: new UUIDS({
                values: payload,
              }),
            })
          )
        ),
      [dispatch]
    ),
    updateOrgInfo: useCallback(
      (payload) => {
        dispatch(
          updateOrgInfo(
            new UpdateOrgInfoRequest({
              orgId: new UUID({ value: payload.orgId }),
              about: payload.about,
            })
          )
        );
      },
      [dispatch]
    ),
  };
};
