import Joi from 'joi';
import { Profile } from '@proto/profiler/user/v1/profile_pb';

export enum FieldNames {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  NICKNAME = 'nickName',
  BIRTHDAY = 'birthDate',
}

export const FIELD_LABELS = {
  [FieldNames.FIRSTNAME]: 'First name',
  [FieldNames.LASTNAME]: 'Last name',
  [FieldNames.NICKNAME]: 'NickName',
};

// @ts-ignore
export const getDefaultValues = (data: Profile | undefined) => ({
  names: {
    [FieldNames.FIRSTNAME]: data?.name?.firstName || '',
    [FieldNames.LASTNAME]: data?.name?.lastName || '',
    [FieldNames.NICKNAME]: data?.name?.nickName || '',
  },
  [FieldNames.BIRTHDAY]:
    data?.birthDate && data?.birthDate.toString() !== '0001-01-01T00:00:00Z'
      ? new Date(data?.birthDate.toString())
      : null,
});

export const myProfileNameFormSchema = {
  names: Joi.object({
    [FieldNames.FIRSTNAME]: Joi.string().required().min(3).max(20).messages({
      'string.empty': 'fill in the first name',
      'string.min': 'first name must be at least 3 characters long',
      'string.max': 'first name must be less than or equal to 30 characters long',
    }),
    [FieldNames.LASTNAME]: Joi.string().required().min(3).max(20).messages({
      'string.empty': 'fill in the last name',
      'string.min': 'last name must be at least 3 characters long',
      'string.max': 'last name must be less than or equal to 30 characters long',
    }),
    [FieldNames.NICKNAME]: Joi.string().required().min(3).max(30).messages({
      'string.empty': 'nickname is not allowed to be empty',
      'string.min': 'nickname must be at least 3 characters long',
      'string.max': 'nickname must be less than or equal to 30 characters long',
    }),
  }),
  [FieldNames.BIRTHDAY]: Joi.date().required().messages({
    'date.base': 'fill in a valid date of birth',
    'any.required': 'fill in the date of birth',
  }),
};
