import { combineReducers } from '@reduxjs/toolkit';
import {
  demandService,
  GetEvaluationServiceRequest,
  GetEvaluationServiceResponse,
  ListCandidatesServiceRequest,
  ListCandidatesServiceResponse,
  ListEvaluationServiceResponse,
} from '@services/api/marketplace/demand';
import { marketplaceSlice } from '@store/marketplace/slices';
import { useCallback } from 'react';
import {
  EvaluationMap,
  TDashboardMapItem,
  TEvaluation,
  TUnitedDemand,
} from '@store/marketplace/marketplace';
import { MatchedContracts } from '@proto/marketplace/demand/v1/contractor_pb';
import { ObjectParam, useQueryParam } from 'use-query-params';
import { ListProfilesRequest } from '@proto/profiler/user/v1/profile_pb';
import { ListCandidatesRequest } from '@proto/marketplace/demand/v1/candidate_pb';
import { getListProfiles } from '@store/profiler';
import { GetEvaluationRequest } from '@proto/marketplace/demand/v1/evaluation_pb';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';
import { createServiceCall, useAppDispatch, useAppSelector } from '../helpers';

const {
  setDisplayListType,
  setCandidatesList,
  setListEvaluations,
  setListInterviewEvaluations,
  setCurrentContractorId,
  setContractors,
} = marketplaceSlice.actions;

export const marketplaceReducer = combineReducers({
  marketplace: marketplaceSlice.reducer,
});

const listCandidates = createServiceCall<
  ListCandidatesServiceResponse,
  ListCandidatesServiceRequest
>(marketplaceSlice.name, demandService.listCandidates, ({ payload, response, dispatch }) => {
  dispatch(
    setCandidatesList({ [payload?.interviewId?.value as string]: response.candidates?.profiles })
  );
  dispatch(
    getListProfiles(
      new ListProfilesRequest({
        profiles: {
          values: response.candidates?.profiles.map(
            (candidate) => candidate.profileId?.value || ''
          ),
        },
      })
    )
  );
});

const listEvaluations = createServiceCall<ListEvaluationServiceResponse, never>(
  marketplaceSlice.name,
  demandService.listEvaluations,
  ({ response, dispatch }) => {
    dispatch(
      setListEvaluations(
        response.evaluations?.evaluations.reduce((acc, evaluation) => {
          acc[evaluation?.evaluationId?.value as string] = evaluation;
          return acc;
        }, {} as EvaluationMap)
      )
    );
    dispatch(
      setListInterviewEvaluations(
        response.evaluations?.evaluations.reduce((acc, evaluation) => {
          acc[evaluation?.interviewId?.value as string] = evaluation;
          return acc;
        }, {} as EvaluationMap)
      )
    );
  }
);

const getEvaluation = createServiceCall<GetEvaluationServiceResponse, GetEvaluationServiceRequest>(
  marketplaceSlice.name,
  demandService.getEvaluation,
  ({ response, dispatch }) => {
    dispatch(
      setListEvaluations({
        [response?.evaluation?.evaluationId?.value || '']: response.evaluation,
      })
    );
  }
);

export const useMarketplace = (): {
  contractors: MatchedContracts[] | undefined;
  displayListType: string;
  demandIdFromUrl: { [key: string]: string | undefined } | null | undefined;
  dashboardsMap: { [key: string]: TDashboardMapItem };
  foundDemands: { [key: string]: TUnitedDemand };
  preAppliesContracts: string[];
  evaluationList: { [key: string]: TEvaluation };
  evaluationsByManager: { [key: string]: TEvaluation[] };
  currentContractorId: { id: UUID; case: 'orgId' | 'squadId' } | undefined;
  setDisplayListType: (payload: string) => void;
  setCurrentContractorId: (payload: { id: string; case: 'org' | 'squad' }) => void;
  setContractors: (payload: MatchedContracts[]) => void;
  setDemandId: (value: { [key: string]: string | undefined } | null | undefined) => void;
  listCandidates: (payload: { interviewId: string }) => void;
  listEvaluations: () => void;
  getEvaluation: (payload: string) => void;
} => {
  const [demandIdFromUrl, setDemandId] = useQueryParam('demandId', ObjectParam);
  const dispatch = useAppDispatch();

  return {
    contractors: useAppSelector((state) => state.marketplaceReducer.marketplace.contractors),

    demandIdFromUrl,
    displayListType: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.displayListType
    ),
    currentContractorId: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.currentContractorId
    ),
    foundDemands: useAppSelector((state) => state.marketplaceReducer.marketplace.foundDemands),
    dashboardsMap: useAppSelector((state) => state.marketplaceReducer.marketplace.dashboardsMap),
    preAppliesContracts: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.preAppliesContracts
    ),
    evaluationList: useAppSelector((state) => state.marketplaceReducer.marketplace.listEvaluations),
    evaluationsByManager: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.listEvaluationsByManager
    ),

    setContractors: (payload) => {
      dispatch(setContractors(payload));
    },

    setDisplayListType: (payload) => {
      dispatch(setDisplayListType(payload));
    },
    setCurrentContractorId: useCallback(
      (payload) => {
        dispatch(setCurrentContractorId(payload));
      },
      [dispatch]
    ),

    setDemandId: useCallback(
      (value) => {
        setDemandId(value);
      },
      [setDemandId]
    ),

    listCandidates: useCallback(
      (payload) => {
        dispatch(
          listCandidates(
            new ListCandidatesRequest({
              interviewId: new UUID({ value: payload.interviewId }),
            })
          )
        );
      },
      [dispatch]
    ),

    listEvaluations: useCallback(() => {
      dispatch(listEvaluations());
    }, [dispatch]),

    getEvaluation: useCallback(
      (payload) => {
        dispatch(
          getEvaluation(new GetEvaluationRequest({ evaluationId: new UUID({ value: payload }) }))
        );
      },
      [dispatch]
    ),
  };
};

export {};
