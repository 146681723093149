import * as React from 'react';
import { Box, Container } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import { useListFeedItems, useListFeedItemsByDemand } from '@services/queries/MarketplaceQueries';
import { Entity, Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import SidebarProfileSettings from '@pages/Main/SidebarProfileSettings/SidebarProfileSettings';
import FeedList from '@pages/Marketplace/Feed/FeedList';
import FeedByDemandsDialog from '@pages/Marketplace/Feed/FeedByDemandsDialog';
import Grid from '@mui/material/Grid';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import FilterFeedItems from '@pages/Marketplace/Feed/FilterFeedItems';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Typography from '@mui/material/Typography';
import { UUID } from '@proto/grpc/type/v1/uuid_pb';

export const demandsTypes = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const FeedPage = () => {
  const { demandIdFromUrl } = useMarketplace();
  const { data } = useListFeedItems();
  const {
    data: feedByContract,
    isPending,
    isLoading: feedByContractLoading,
  } = useListFeedItemsByDemand(
    demandIdFromUrl
      ? new Entity({
          id: new UUID({ value: demandIdFromUrl.demandId }),
          type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
        })
      : undefined
  );

  return (
    <Container>
      {feedByContractLoading && <LoaderWithDelay />}
      <Grid container sx={{ position: 'relative' }} spacing={2}>
        <Grid item xs={3} sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
          <Box
            sx={{
              position: 'fixed',
              left: 'auto',
              right: 'auto',
              width: '320px',
              height: '100vh',
              overflowY: 'auto',
            }}
          >
            <SidebarProfileSettings />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box mb={1}>
            <Box display="flex" mb={1} gap={1}>
              <EventAvailableIcon />
              <Typography variant="subtitle1">Check what’s in demand today</Typography>
            </Box>
            <FilterFeedItems />
          </Box>

          <Box sx={{ width: '100%' }} display="flex" gap={1}>
            {data?.feedItems && (
              <Box width="800px">
                <FeedList feedItems={data?.feedItems} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {!isPending && (
        <FeedByDemandsDialog
          feedOwner={feedByContract?.feedOwner}
          feedItems={feedByContract?.feedItems}
        />
      )}
    </Container>
  );
};

export default FeedPage;
