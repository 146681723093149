import React from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import { Entity_Type } from '@proto/grpc/type/v1/entity_pb';
import PinUnpinButton from '@components/PinUnpinButton/PinUnpinButton';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';

const typesDemand = {
  contract: Entity_Type.CONTRACT,
  interview: Entity_Type.INTERVIEW,
};

const DemandCard = ({
  demand,
  isPreApplied,
  showActiveState,
  errors,
  recommendations,
}: {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
  errors?: { [key: string]: boolean };
  recommendations: any;
}) => {
  const { demandIdFromUrl, setDemandId } = useMarketplace();
  const demandId = getDemandId(demand);
  const matches =
    (demandId &&
      Object?.values(
        // @ts-ignore
        recommendations?.recommendations[
          // @ts-ignore
          demandId as keyof typeof recommendations.recommendations
        ]?.recommendations || {}
        // @ts-ignore
      ).map((recommendation) => recommendation?.suppliers.map((supplier) => supplier))) ||
    [];

  const toggleDemand = () => {
    setDemandId({ demandId: demandId?.value || '', demandType: demand?.entity?.case || '' });
  };

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Box
      onClick={toggleDemand}
      sx={{ cursor: 'pointer' }}
      className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}
      width="80%"
    >
      {isPreApplied && <ContractorSelect contractId={demandId?.value} />}
      {Object.keys(errors || {}).some((key) => key === demandId?.value)
        ? 'Choose contractor'
        : null}
      <Card>
        <Box display="flex" gap={0.5} flexDirection="column" justifyContent="center">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2}>
              <Box display="flex" alignItems="center">
                <Typography fontSize="13px" fontWeight="bold">
                  {demand?.entity?.case?.toUpperCase()}
                </Typography>
              </Box>
              {demand.entity.value?.tags?.tags
                .filter((tag) => tag.tagType === TagType.ROLE)
                .map((tag) => {
                  return <Chip variant="outlined" label={tag.value} />;
                })}
            </Box>

            {demand?.entity?.case && demandId && (
              <Box onClick={handleCheckboxClick}>
                <PinUnpinButton type={typesDemand[demand?.entity?.case]} id={demandId?.value} />
              </Box>
            )}
            {/* <Typography fontSize="13px"> */}
            {/*  {format(new Date(demand.entity.value?.createdAt?.toDate() || ''), 'dd/MM/yyyy')} */}
            {/* </Typography> */}
          </Box>
          <Divider />
          <Box display="flex" gap={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={1}>
              <DemandTraits
                matches={matches.length && matches[0][0]?.traits}
                flexDirection="row"
                fontSize="13px"
                gap={2}
                demand={demand.entity.value}
              />
              {demand.entity.value?.tags?.tags && (
                <Tags
                  matchedTags={matches.length && matches[0][0]?.matchedTags?.tags}
                  showType
                  tags={demand.entity.value?.tags?.tags}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default DemandCard;
