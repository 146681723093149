import { Box, Typography } from '@mui/material';
import React from 'react';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import DemandCard from '@pages/Marketplace/Feed/DemandCard'; // імпортуємо ваш компонент DemandCard

interface Props {
  displayList: Demand[];
  recommendations?: any;
}

export function sortItemsByDate(items: Demand[]) {
  return items?.sort((a, b) => {
    const dateA = new Date(a?.entity?.value?.createdAt?.toDate() || '');
    const dateB = new Date(b?.entity?.value?.createdAt?.toDate() || '');

    return dateB.getTime() - dateA.getTime();
  });
}

const DemandList: React.FC<Props> = ({ displayList, recommendations }) => {
  const sortedList = sortItemsByDate(displayList);
  const displayedDates = new Map<string, boolean>();

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      {sortedList &&
        sortedList.map((demand) => {
          const date = new Date(
            demand?.entity?.value?.createdAt?.toDate() || ''
          ).toLocaleDateString();
          let showDate = false;

          if (!displayedDates.has(date)) {
            displayedDates.set(date, true);
            showDate = true;
          }

          return (
            <Box key={demand.entity.value?.dashboardId?.value} display="flex" width="100%" gap={2}>
              <DemandCard showActiveState demand={demand} recommendations={recommendations} />
              {showDate ? (
                <Box
                  display="flex"
                  width="10%"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  gap={1.5}
                >
                  <Typography fontSize="10px" color="textSecondary">
                    {date}
                  </Typography>
                  <Box
                    sx={{
                      height: '110%',
                      width: '1px',
                      backgroundColor: '#424242',
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                  />
                </Box>
              ) : (
                <Box
                  width="10%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box
                    sx={{
                      height: '120%',
                      width: '1px',
                      backgroundColor: '#424242',
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};

export default DemandList;
