import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Header } from '@components/Header/Header';

interface Props {
  children?: React.ReactNode;
}

export const DashboardContainer = (props: Props) => {
  const { children } = props;

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Box sx={{ py: { lg: '2rem', md: 5 } }} display="flex" flexDirection="column" width="100%">
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
