// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file notificator/stream/v1/notifications.proto (package notificator.stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Groups, Message as Message$1, Messages } from './chat_pb.js';
import { EventMessageType } from './events_pb.js';

/**
 * @generated from message notificator.stream.v1.SubscribeRequest
 */
export class SubscribeRequest extends Message<SubscribeRequest> {
  constructor(data?: PartialMessage<SubscribeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SubscribeRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined,
    b: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.SubscribeResponse
 */
export class SubscribeResponse extends Message<SubscribeResponse> {
  /**
   * @generated from oneof notificator.stream.v1.SubscribeResponse.payload
   */
  payload:
    | {
        /**
         * @generated from field: notificator.stream.v1.Connect groups = 1;
         */
        value: Connect;
        case: 'groups';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Bell bell = 2;
         */
        value: Bell;
        case: 'bell';
      }
    | {
        /**
         * @generated from field: notificator.stream.v1.Message message = 3;
         */
        value: Message$1;
        case: 'message';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SubscribeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SubscribeResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'groups', kind: 'message', T: Connect, oneof: 'payload' },
    { no: 2, name: 'bell', kind: 'message', T: Bell, oneof: 'payload' },
    { no: 3, name: 'message', kind: 'message', T: Message$1, oneof: 'payload' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined,
    b: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Connect
 */
export class Connect extends Message<Connect> {
  /**
   * @generated from field: notificator.stream.v1.Groups groups = 1;
   */
  groups?: Groups;

  /**
   * @generated from field: notificator.stream.v1.Messages messages = 2;
   */
  messages?: Messages;

  constructor(data?: PartialMessage<Connect>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Connect';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'groups', kind: 'message', T: Groups },
    { no: 2, name: 'messages', kind: 'message', T: Messages },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Connect {
    return new Connect().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Connect {
    return new Connect().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Connect {
    return new Connect().fromJsonString(jsonString, options);
  }

  static equals(
    a: Connect | PlainMessage<Connect> | undefined,
    b: Connect | PlainMessage<Connect> | undefined
  ): boolean {
    return proto3.util.equals(Connect, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Bell
 */
export class Bell extends Message<Bell> {
  /**
   * @generated from field: string subject = 1;
   */
  subject = '';

  /**
   * @generated from field: string message = 2;
   */
  message = '';

  /**
   * @generated from field: notificator.stream.v1.EventMessageType message_type = 3;
   */
  messageType = EventMessageType.UNSPECIFIED;

  constructor(data?: PartialMessage<Bell>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Bell';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'message_type', kind: 'enum', T: proto3.getEnumType(EventMessageType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Bell {
    return new Bell().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Bell {
    return new Bell().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Bell {
    return new Bell().fromJsonString(jsonString, options);
  }

  static equals(
    a: Bell | PlainMessage<Bell> | undefined,
    b: Bell | PlainMessage<Bell> | undefined
  ): boolean {
    return proto3.util.equals(Bell, a, b);
  }
}
