// OAuthButton.tsx
import qs from 'qs';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useAuth } from '@store/auth';
import { GradientButton } from '@components/GradientButton/GradientButton';
import { GoogleOAuth } from '@proto/iam/auth/v1/auth_pb';
import { usePreSignIn } from '@services/queries/AuthQueries';
import { getButtonLogo, getButtonText, oAuthAppTypeQueryParam, OAuthAppTypes } from './oAuthConfig';

export type OAuthButtonProps = {
  type: OAuthAppTypes;
  sx?: Record<string, any>;
};

export const OAuthButton: React.FC<OAuthButtonProps> = ({ type, sx = {} }) => {
  const { isLoading, oAuthSignIn, getDevice, device } = useAuth();
  const { mutate } = usePreSignIn();

  useEffect(() => {
    getDevice();
  }, [getDevice]);

  useEffect(() => {
    const url = window.location.href;
    const [baseUrl, paramsStr] = url.split('?');
    const params = qs.parse(paramsStr);

    if (params.error) {
      window.alert(params.error_description || params.error);
      window.history.pushState({}, '', baseUrl);
      return;
    }

    if (params.code && params[oAuthAppTypeQueryParam] === type) {
      window.history.pushState({}, '', baseUrl);
    }
    if (params.code && params.state) {
      oAuthSignIn({
        google: new GoogleOAuth({
          code: params.code as string,
          state: params.state as string,
        }),
        device: device || undefined,
      });
    }
  }, [type, oAuthSignIn, device]);

  const handleLoginClick = () => {
    mutate();
  };

  return (
    <GradientButton onClick={handleLoginClick} disabled={isLoading} sx={sx} fullWidth>
      <img src={getButtonLogo(type)} alt={`${type}-logo`} />
      <Typography variant="button" ml={2}>
        {getButtonText(type)}
      </Typography>
    </GradientButton>
  );
};
