import { useMarketplace } from '@store/marketplace';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { MatchedContracts } from '@proto/marketplace/demand/v1/contractor_pb';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import { UUID, UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { useListUserSquads } from '@services/queries/SquadsQueries';
import { useListUserOrgs } from '@services/queries/OrgsQueries';
import { useListMembers } from '@services/queries/MembershipsQueries';
import { ListMembersRequest } from '@proto/iam/membership/v1/membership_pb';

const ContractorSelect = ({ contractId }: { contractId?: string }) => {
  const { currentContractorId, setContractors, contractors } = useMarketplace();
  const [userContractorrId, setUserContractorId] = useState<string | undefined>(undefined);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const { data: squadsList } = useListUserSquads();
  const { data: orgsList } = useListUserOrgs();
  const squadsEntityIds = squadsList?.squads?.squads?.map((squad) => squad?.squadId?.value || '');
  const orgsEntityIds = orgsList?.orgs?.orgs?.map((org) => org?.orgId?.value || '');

  const { data: squadMembers } = useListMembers(
    new ListMembersRequest({
      entityIds: new UUIDS({ values: squadsEntityIds }),
    }),
    !!squadsEntityIds?.length // enabled буде true, тільки якщо є значення
  );

  const { data: orgMembers } = useListMembers(
    new ListMembersRequest({
      entityIds: new UUIDS({ values: orgsEntityIds }),
    }),
    !!orgsEntityIds?.length // enabled буде true, тільки якщо є значення
  );

  const listMembers = { ...squadMembers?.members, ...orgMembers?.members };

  const handleToggle = () => {
    setShowFirstButton(!showFirstButton);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setContractors([
      new MatchedContracts({
        profileId: new UUID({ value: event.target.value }),
        contractId: new UUID({ value: contractId }),
      }),
      ...(contractors || []),
    ]);
    setUserContractorId(event.target.value as string);
  };
  return currentContractorId ? (
    <Box>
      <Box>
        {showFirstButton ? (
          <Button fullWidth onClick={handleToggle} variant="contained">
            <Typography variant="subtitle2">Choose contractor for this demand</Typography>
          </Button>
        ) : (
          <Box display="flex" gap={1} alignItems="center">
            <Select value={userContractorrId} onChange={handleChange} fullWidth>
              {listMembers[
                currentContractorId?.id.value as keyof typeof listMembers
              ]?.profiles?.map((profile) => {
                return (
                  <MenuItem value={profile.profileId?.value} key={profile.profileId?.value}>
                    {profile?.name?.nickName}
                  </MenuItem>
                );
              })}
            </Select>
            <HighlightOffIcon onClick={handleToggle} />
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export default ContractorSelect;
