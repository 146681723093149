// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file notificator/stream/v1/chat.proto (package notificator.stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message as Message$1, proto3, Timestamp } from '@bufbuild/protobuf';
import { UUID, UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { ProfileName } from '../../../profiler/user/v1/profile_pb.js';

/**
 * @generated from message notificator.stream.v1.SendMessageRequest
 */
export class SendMessageRequest extends Message$1<SendMessageRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  /**
   * @generated from field: string text = 2;
   */
  text = '';

  constructor(data?: PartialMessage<SendMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SendMessageRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
    { no: 2, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendMessageRequest {
    return new SendMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendMessageRequest {
    return new SendMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendMessageRequest {
    return new SendMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendMessageRequest | PlainMessage<SendMessageRequest> | undefined,
    b: SendMessageRequest | PlainMessage<SendMessageRequest> | undefined
  ): boolean {
    return proto3.util.equals(SendMessageRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.SendMessageResponse
 */
export class SendMessageResponse extends Message$1<SendMessageResponse> {
  constructor(data?: PartialMessage<SendMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SendMessageResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendMessageResponse {
    return new SendMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendMessageResponse {
    return new SendMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendMessageResponse {
    return new SendMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendMessageResponse | PlainMessage<SendMessageResponse> | undefined,
    b: SendMessageResponse | PlainMessage<SendMessageResponse> | undefined
  ): boolean {
    return proto3.util.equals(SendMessageResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.EditMessageRequest
 */
export class EditMessageRequest extends Message$1<EditMessageRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID message_id = 1;
   */
  messageId?: UUID;

  /**
   * @generated from field: string text = 2;
   */
  text = '';

  constructor(data?: PartialMessage<EditMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.EditMessageRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message_id', kind: 'message', T: UUID },
    { no: 2, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditMessageRequest {
    return new EditMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditMessageRequest {
    return new EditMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EditMessageRequest {
    return new EditMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: EditMessageRequest | PlainMessage<EditMessageRequest> | undefined,
    b: EditMessageRequest | PlainMessage<EditMessageRequest> | undefined
  ): boolean {
    return proto3.util.equals(EditMessageRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.EditMessageResponse
 */
export class EditMessageResponse extends Message$1<EditMessageResponse> {
  constructor(data?: PartialMessage<EditMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.EditMessageResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditMessageResponse {
    return new EditMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditMessageResponse {
    return new EditMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EditMessageResponse {
    return new EditMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: EditMessageResponse | PlainMessage<EditMessageResponse> | undefined,
    b: EditMessageResponse | PlainMessage<EditMessageResponse> | undefined
  ): boolean {
    return proto3.util.equals(EditMessageResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.RemoveMessageRequest
 */
export class RemoveMessageRequest extends Message$1<RemoveMessageRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID message_id = 1;
   */
  messageId?: UUID;

  constructor(data?: PartialMessage<RemoveMessageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.RemoveMessageRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMessageRequest {
    return new RemoveMessageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMessageRequest {
    return new RemoveMessageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMessageRequest {
    return new RemoveMessageRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMessageRequest | PlainMessage<RemoveMessageRequest> | undefined,
    b: RemoveMessageRequest | PlainMessage<RemoveMessageRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMessageRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.RemoveMessageResponse
 */
export class RemoveMessageResponse extends Message$1<RemoveMessageResponse> {
  constructor(data?: PartialMessage<RemoveMessageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.RemoveMessageResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveMessageResponse {
    return new RemoveMessageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMessageResponse {
    return new RemoveMessageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMessageResponse {
    return new RemoveMessageResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMessageResponse | PlainMessage<RemoveMessageResponse> | undefined,
    b: RemoveMessageResponse | PlainMessage<RemoveMessageResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMessageResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.GetMessagesHistoryRequest
 */
export class GetMessagesHistoryRequest extends Message$1<GetMessagesHistoryRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  /**
   * @generated from field: google.protobuf.Timestamp offset = 2;
   */
  offset?: Timestamp;

  constructor(data?: PartialMessage<GetMessagesHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.GetMessagesHistoryRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
    { no: 2, name: 'offset', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetMessagesHistoryRequest {
    return new GetMessagesHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetMessagesHistoryRequest {
    return new GetMessagesHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMessagesHistoryRequest {
    return new GetMessagesHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMessagesHistoryRequest | PlainMessage<GetMessagesHistoryRequest> | undefined,
    b: GetMessagesHistoryRequest | PlainMessage<GetMessagesHistoryRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetMessagesHistoryRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.GetMessagesHistoryResponse
 */
export class GetMessagesHistoryResponse extends Message$1<GetMessagesHistoryResponse> {
  /**
   * @generated from field: notificator.stream.v1.Messages messages = 1;
   */
  messages?: Messages;

  constructor(data?: PartialMessage<GetMessagesHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.GetMessagesHistoryResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'messages', kind: 'message', T: Messages },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetMessagesHistoryResponse {
    return new GetMessagesHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetMessagesHistoryResponse {
    return new GetMessagesHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMessagesHistoryResponse {
    return new GetMessagesHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMessagesHistoryResponse | PlainMessage<GetMessagesHistoryResponse> | undefined,
    b: GetMessagesHistoryResponse | PlainMessage<GetMessagesHistoryResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetMessagesHistoryResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.JoinGroupRequest
 */
export class JoinGroupRequest extends Message$1<JoinGroupRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  constructor(data?: PartialMessage<JoinGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.JoinGroupRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinGroupRequest {
    return new JoinGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinGroupRequest {
    return new JoinGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinGroupRequest {
    return new JoinGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: JoinGroupRequest | PlainMessage<JoinGroupRequest> | undefined,
    b: JoinGroupRequest | PlainMessage<JoinGroupRequest> | undefined
  ): boolean {
    return proto3.util.equals(JoinGroupRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.JoinGroupResponse
 */
export class JoinGroupResponse extends Message$1<JoinGroupResponse> {
  /**
   * @generated from field: notificator.stream.v1.Group group = 1;
   */
  group?: Group;

  constructor(data?: PartialMessage<JoinGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.JoinGroupResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group', kind: 'message', T: Group },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinGroupResponse {
    return new JoinGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinGroupResponse {
    return new JoinGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinGroupResponse {
    return new JoinGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: JoinGroupResponse | PlainMessage<JoinGroupResponse> | undefined,
    b: JoinGroupResponse | PlainMessage<JoinGroupResponse> | undefined
  ): boolean {
    return proto3.util.equals(JoinGroupResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.LeaveGroupRequest
 */
export class LeaveGroupRequest extends Message$1<LeaveGroupRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  constructor(data?: PartialMessage<LeaveGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.LeaveGroupRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveGroupRequest | PlainMessage<LeaveGroupRequest> | undefined,
    b: LeaveGroupRequest | PlainMessage<LeaveGroupRequest> | undefined
  ): boolean {
    return proto3.util.equals(LeaveGroupRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.LeaveGroupResponse
 */
export class LeaveGroupResponse extends Message$1<LeaveGroupResponse> {
  constructor(data?: PartialMessage<LeaveGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.LeaveGroupResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveGroupResponse {
    return new LeaveGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveGroupResponse {
    return new LeaveGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LeaveGroupResponse {
    return new LeaveGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveGroupResponse | PlainMessage<LeaveGroupResponse> | undefined,
    b: LeaveGroupResponse | PlainMessage<LeaveGroupResponse> | undefined
  ): boolean {
    return proto3.util.equals(LeaveGroupResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.SetChatMemberRoleRequest
 */
export class SetChatMemberRoleRequest extends Message$1<SetChatMemberRoleRequest> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 2;
   */
  profileId?: UUID;

  /**
   * @generated from field: notificator.stream.v1.ChatMember.Role role = 3;
   */
  role = ChatMember_Role.UNSPECIFIED;

  constructor(data?: PartialMessage<SetChatMemberRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SetChatMemberRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
    { no: 2, name: 'profile_id', kind: 'message', T: UUID },
    { no: 3, name: 'role', kind: 'enum', T: proto3.getEnumType(ChatMember_Role) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetChatMemberRoleRequest {
    return new SetChatMemberRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetChatMemberRoleRequest {
    return new SetChatMemberRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetChatMemberRoleRequest {
    return new SetChatMemberRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetChatMemberRoleRequest | PlainMessage<SetChatMemberRoleRequest> | undefined,
    b: SetChatMemberRoleRequest | PlainMessage<SetChatMemberRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetChatMemberRoleRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.SetChatMemberRoleResponse
 */
export class SetChatMemberRoleResponse extends Message$1<SetChatMemberRoleResponse> {
  constructor(data?: PartialMessage<SetChatMemberRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.SetChatMemberRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetChatMemberRoleResponse {
    return new SetChatMemberRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetChatMemberRoleResponse {
    return new SetChatMemberRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetChatMemberRoleResponse {
    return new SetChatMemberRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetChatMemberRoleResponse | PlainMessage<SetChatMemberRoleResponse> | undefined,
    b: SetChatMemberRoleResponse | PlainMessage<SetChatMemberRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetChatMemberRoleResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.InitGroupRequest
 */
export class InitGroupRequest extends Message$1<InitGroupRequest> {
  /**
   * @generated from oneof notificator.stream.v1.InitGroupRequest.with
   */
  with:
    | {
        /**
         * @generated from field: grpc.type.v1.UUID squad_id = 1;
         */
        value: UUID;
        case: 'squadId';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS profile_ids = 2;
         */
        value: UUIDS;
        case: 'profileIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<InitGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.InitGroupRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'squad_id', kind: 'message', T: UUID, oneof: 'with' },
    { no: 2, name: 'profile_ids', kind: 'message', T: UUIDS, oneof: 'with' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitGroupRequest {
    return new InitGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitGroupRequest {
    return new InitGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitGroupRequest {
    return new InitGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: InitGroupRequest | PlainMessage<InitGroupRequest> | undefined,
    b: InitGroupRequest | PlainMessage<InitGroupRequest> | undefined
  ): boolean {
    return proto3.util.equals(InitGroupRequest, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.InitGroupResponse
 */
export class InitGroupResponse extends Message$1<InitGroupResponse> {
  /**
   * @generated from field: notificator.stream.v1.Group group = 1;
   */
  group?: Group;

  constructor(data?: PartialMessage<InitGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.InitGroupResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group', kind: 'message', T: Group },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitGroupResponse {
    return new InitGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitGroupResponse {
    return new InitGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitGroupResponse {
    return new InitGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: InitGroupResponse | PlainMessage<InitGroupResponse> | undefined,
    b: InitGroupResponse | PlainMessage<InitGroupResponse> | undefined
  ): boolean {
    return proto3.util.equals(InitGroupResponse, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Message
 */
export class Message extends Message$1<Message> {
  /**
   * @generated from field: grpc.type.v1.UUID message_id = 1;
   */
  messageId?: UUID;

  /**
   * @generated from field: grpc.type.v1.UUID group_id = 2;
   */
  groupId?: UUID;

  /**
   * sender
   *
   * @generated from field: grpc.type.v1.UUID profile_id = 3;
   */
  profileId?: UUID;

  /**
   * @generated from field: string text = 4;
   */
  text = '';

  /**
   * @generated from field: repeated string file_names = 5;
   */
  fileNames: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created = 6;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 7;
   */
  updated?: Timestamp;

  constructor(data?: PartialMessage<Message>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Message';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message_id', kind: 'message', T: UUID },
    { no: 2, name: 'group_id', kind: 'message', T: UUID },
    { no: 3, name: 'profile_id', kind: 'message', T: UUID },
    { no: 4, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'file_names', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: 'created', kind: 'message', T: Timestamp },
    { no: 7, name: 'updated', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message {
    return new Message().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJsonString(jsonString, options);
  }

  static equals(
    a: Message | PlainMessage<Message> | undefined,
    b: Message | PlainMessage<Message> | undefined
  ): boolean {
    return proto3.util.equals(Message, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Messages
 */
export class Messages extends Message$1<Messages> {
  /**
   * @generated from field: repeated notificator.stream.v1.Message messages = 1;
   */
  messages: Message[] = [];

  constructor(data?: PartialMessage<Messages>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Messages';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'messages', kind: 'message', T: Message, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Messages {
    return new Messages().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Messages {
    return new Messages().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Messages {
    return new Messages().fromJsonString(jsonString, options);
  }

  static equals(
    a: Messages | PlainMessage<Messages> | undefined,
    b: Messages | PlainMessage<Messages> | undefined
  ): boolean {
    return proto3.util.equals(Messages, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.ChatMember
 */
export class ChatMember extends Message$1<ChatMember> {
  /**
   * @generated from field: grpc.type.v1.UUID profile_id = 1;
   */
  profileId?: UUID;

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 2;
   */
  name?: ProfileName;

  /**
   * @generated from field: grpc.type.v1.UUID group_id = 3;
   */
  groupId?: UUID;

  /**
   * @generated from field: notificator.stream.v1.ChatMember.Role role = 4;
   */
  role = ChatMember_Role.UNSPECIFIED;

  /**
   * @generated from field: notificator.stream.v1.ChatMember.Status status = 5;
   */
  status = ChatMember_Status.UNSPECIFIED;

  constructor(data?: PartialMessage<ChatMember>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.ChatMember';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'message', T: UUID },
    { no: 2, name: 'name', kind: 'message', T: ProfileName },
    { no: 3, name: 'group_id', kind: 'message', T: UUID },
    { no: 4, name: 'role', kind: 'enum', T: proto3.getEnumType(ChatMember_Role) },
    { no: 5, name: 'status', kind: 'enum', T: proto3.getEnumType(ChatMember_Status) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChatMember {
    return new ChatMember().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChatMember {
    return new ChatMember().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChatMember {
    return new ChatMember().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChatMember | PlainMessage<ChatMember> | undefined,
    b: ChatMember | PlainMessage<ChatMember> | undefined
  ): boolean {
    return proto3.util.equals(ChatMember, a, b);
  }
}

/**
 * @generated from enum notificator.stream.v1.ChatMember.Role
 */
export enum ChatMember_Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLE_USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: ROLE_ADMIN = 2;
   */
  ADMIN = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ChatMember_Role)
proto3.util.setEnumType(ChatMember_Role, 'notificator.stream.v1.ChatMember.Role', [
  { no: 0, name: 'ROLE_UNSPECIFIED' },
  { no: 1, name: 'ROLE_USER' },
  { no: 2, name: 'ROLE_ADMIN' },
]);

/**
 * @generated from enum notificator.stream.v1.ChatMember.Status
 */
export enum ChatMember_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: STATUS_ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: STATUS_DELETED = 3;
   */
  DELETED = 3,

  /**
   * @generated from enum value: STATUS_LEFT = 4;
   */
  LEFT = 4,

  /**
   * @generated from enum value: STATUS_BANNED = 5;
   */
  BANNED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ChatMember_Status)
proto3.util.setEnumType(ChatMember_Status, 'notificator.stream.v1.ChatMember.Status', [
  { no: 0, name: 'STATUS_UNSPECIFIED' },
  { no: 1, name: 'STATUS_PENDING' },
  { no: 2, name: 'STATUS_ACTIVE' },
  { no: 3, name: 'STATUS_DELETED' },
  { no: 4, name: 'STATUS_LEFT' },
  { no: 5, name: 'STATUS_BANNED' },
]);

/**
 * @generated from message notificator.stream.v1.Group
 */
export class Group extends Message$1<Group> {
  /**
   * @generated from field: grpc.type.v1.UUID group_id = 1;
   */
  groupId?: UUID;

  /**
   * @generated from field: string session_id = 2;
   */
  sessionId = '';

  /**
   * @generated from field: repeated notificator.stream.v1.ChatMember members = 3;
   */
  members: ChatMember[] = [];

  constructor(data?: PartialMessage<Group>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Group';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'group_id', kind: 'message', T: UUID },
    { no: 2, name: 'session_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'members', kind: 'message', T: ChatMember, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Group {
    return new Group().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Group {
    return new Group().fromJsonString(jsonString, options);
  }

  static equals(
    a: Group | PlainMessage<Group> | undefined,
    b: Group | PlainMessage<Group> | undefined
  ): boolean {
    return proto3.util.equals(Group, a, b);
  }
}

/**
 * @generated from message notificator.stream.v1.Groups
 */
export class Groups extends Message$1<Groups> {
  /**
   * @generated from field: repeated notificator.stream.v1.Group groups = 1;
   */
  groups: Group[] = [];

  constructor(data?: PartialMessage<Groups>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.stream.v1.Groups';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'groups', kind: 'message', T: Group, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Groups {
    return new Groups().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Groups {
    return new Groups().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Groups {
    return new Groups().fromJsonString(jsonString, options);
  }

  static equals(
    a: Groups | PlainMessage<Groups> | undefined,
    b: Groups | PlainMessage<Groups> | undefined
  ): boolean {
    return proto3.util.equals(Groups, a, b);
  }
}
