import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Supply, SupplyList } from '@proto/marketplace/demand/v1/supply_pb';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';

export function sortItemsByDate(items: Demand[]) {
  return items?.sort((a, b) => {
    const dateA = new Date(a?.entity?.value?.createdAt?.toDate() || '');
    const dateB = new Date(b?.entity?.value?.createdAt?.toDate() || '');

    return dateB.getTime() - dateA.getTime();
  });
}

interface ExtendedSupply extends Supply {
  contractIds?: string[];
}

export function mergeSupplies(contractEntries: [string, any][]): {
  [key: string]: ExtendedSupply;
} {
  return contractEntries.reduce<{ [key: string]: ExtendedSupply }>(
    (merged, [contractId, contractValue]) => {
      const supplyList = (contractValue.supply?.view.value as SupplyList)?.list;

      supplyList?.forEach((supply) => {
        const supplyId = supply?.supplyId?.value as string;

        if (!merged[supplyId]) {
          const clonedSupply: ExtendedSupply = supply.clone();
          clonedSupply.profileIds = new UUIDS({ values: [...(supply?.profileIds?.values || [])] });
          clonedSupply.contractIds = [contractId];
          merged[supplyId] = clonedSupply;
        } else {
          merged[supplyId].profileIds?.values.push(...(supply?.profileIds?.values || []));
          if (!merged[supplyId].contractIds?.includes(contractId)) {
            merged[supplyId].contractIds?.push(contractId);
          }
        }
      });

      return merged;
    },
    {}
  );
}
